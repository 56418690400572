import {
    browserVersion,
    isChrome,
    isEdge,
    isFirefox,
    isIE,
    isMobile,
    isOpera,
    isSafari
} from "react-device-detect";

// Force mobile view if accessibility mode is toggled, or webGL version is unavailable.
export function mobileView(width, noWidthLimit){
    return (
        accessibilityToggled() ||
        !WebGLVersionAvailable(width, noWidthLimit)
    )
}

// If accessibilityMode is set in localStorage value.
function accessibilityToggled(){
    return (localStorage.listMode && JSON.parse(localStorage.listMode))
}

export function incompatibleBrowserFor3DVersion() {
    return ((isIE) || (isSafari && browserVersion < 15) || (isEdge && browserVersion < 79));
}

// The webGL version is available if :
// - The browser supports webGL
// - It is not on mobile device (for touch control)
// - If there is a width limit, and the width is lower than the limit.
export function WebGLVersionAvailable(width, noWidthLimit){
    return (
        browserWebGLCompatible() &&
        !isMobile &&
        (noWidthLimit || width >= 1000)
    )
}

/*
    Determine if the device viewing the kiosk is a compatible browser for 3D content
    If it is not, then set the listMode flag to true, forcing the device to
    view the accessible content.

    Supported browsers:
    Chrome v56+ (January 2017)
    Firefox v51+ (January 2017)
    Edge v79+ (January 2020)
    Opera v43+ (February 2017)
    Safari v15+ (September 2021)

    Not supported browser:
    Safari < v15 (compatible but disabled by Apple)
    iOS / Android (not touch compatible)
    Edge v12 to v18 (pre-chromium update)
    Internet Explorer (all versions)
*/
function browserWebGLCompatible() {
    if ((isIE) ||
        (isSafari && browserVersion < 15) ||
        (isChrome && browserVersion < 56) ||
        (isFirefox && browserVersion < 51) ||
        (isEdge && browserVersion < 79) ||
        (isOpera && browserVersion < 43)) {
        return false;
    }

    return true;
}

export function setListMode() {
    localStorage.listMode = true;
}
export function setExplorationMode() {
    localStorage.listMode = false;
}

export function isListMode() {
    return localStorage.listMode && JSON.parse(localStorage.listMode);
}
