import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";

import VSpacePlayer from "../organisms/VSpacePlayer";
import IntroMessage from "../organisms/IntroMessage";
import VideoPlayer from "../organisms/VideoPlayer";
import ESDCModalMap from '../organisms/ESDCModalMap';

export default function WebGLSpace(props) {
    let history = useHistory();

    const [showIntroModal, setShowIntroModal] = useState(localStorage.viewIntroMessage === undefined);
    const [showMapModal, setShowMapModal] = useState(false);
    const [videoState, setVideoState] = useState(defaultVideoState());
    const [videoOpened, setVideoOpened] = useState(false);

    // True if any modal is opened
    const openedModal = (showIntroModal || showMapModal || videoOpened || props.isKioskExhibitionShown)

    const videoList = props.space.contentItems.filter(
        content => (content.linkURL && content.linkURL.split('|')[0] === "video")
    )

    useEffect(() => {
        // reset video state when space changes
        setVideoState(defaultVideoState());

        setVideoOpened(false)
    }, [props.space]);

    function closeIntroModal(){setShowIntroModal(false);}

    function openIntroModal(){setShowIntroModal(true);}

    function openMapModal(){setShowMapModal(true)}

    function closeMapModal(){setShowMapModal(false);}

    function openVideoPlayer(contentItemID) {
        let stateDup = {...videoState}
        stateDup[contentItemID] = true

        setVideoState(stateDup)
        setVideoOpened(true)
    }

    function closeVideo() {
        setVideoState(defaultVideoState());

        setVideoOpened(false)
    }

    // Back to previous webGlSpace
    function backToPreviousSpace() {
        if (!props.space.previousSpaceIDWebGL) return

        navigateToSpace(props.space.previousSpaceIDWebGL);
    }

    // Navigate to specific vspace
    function navigateToSpace(vSpaceID) {
        if (!vSpaceID) return

        history.push(`/explore/${props.t.esdc}/${props.t.virtualSpacesPath}/${vSpaceID}`);
    }

    function defaultVideoState() {
        const videos = props.space.contentItems.filter(
            content => (content.linkURL && content.linkURL.split('|')[0] === "video")
        )

        return videos.map(content => content.contentItemID)
                     .reduce((acc, key) => {acc[key] = false; return acc; }, {})
    }

    return (
        <React.Fragment>
            {/* Render intro message */}
            {showIntroModal &&
                <IntroMessage t={props.t} show={showIntroModal} parentCallback={closeIntroModal} vSite={props.vSite}/>
             }

            <VSpacePlayer
                t={props.t}
                openIntroModal={openIntroModal}
                space={props.space}
                toggleModalTablet={props.toggleModalTablet}
                backToPreviousSpace={backToPreviousSpace}
                openMapModal={openMapModal}
                openVideoPlayer={openVideoPlayer}
                navigateToSpace={navigateToSpace}
                openedModal={openedModal}
            />

            {showMapModal &&
                <ESDCModalMap t={props.t} show={showMapModal} parentCallback={closeMapModal} vSpaceID={props.space.vSpaceID}/>
            }

            {/* Render video modals */}
            {videoList.map(content => (
                // Handle when in transition and videoState is not set yet.
                videoState[content.contentItemID] &&
                <VideoPlayer
                    open={videoState[content.contentItemID]}
                    content={content}
                    videoList={videoList}
                    toggleModal={closeVideo}
                    t={props.t}
                    key={content.contentItemID}
                    />
            ))}
        </React.Fragment>
    );
}
