const en = {
    "getLocale": "en",
    "getOppositeLocale": "fr",
    "getOppositeLang": "Français",
    "backToLobby": "Back to Lobby",
    "back": "Back",
    "info": "About the project",
    "map": "Map",
    "headerTitle": "CSPS vExpo (Beta)",
    "search": "Search",
    "Lobby": "Lobby",
    "Kiosks": "Kiosks",
    "kiosk": "kiosk",
    "KioskExhibition":"Kiosk Exhibition",
    "kiosksFound": ":num kiosk|kiosks found",
    "kiosksTotal": ":num kiosk|kiosks total",
    "noKioskFound": "No kiosk found",
    "exhibitionGuide": "Exhibition Guide",
    "mainPresentationPath": "main-presentation",
    "cspsKiosksTitle": "Learn about the Canada School of Public Service.",
    "incompatibleBrowser": "Unsupported Browser",
    "incompatibleBrowserMessage": "It looks like your browser does not support the technology needed to view the 3D kiosk. Change browsers to get the full experience.",
    "incompatibleBrowserUrl": "https://wiki.gccollab.ca/CSPS_Digital_Academy/Virtual_Expo_Browser_Compatibility",
    "gcNetworkInfo": "Some links may only be accessible on GC networks.",
    "warning": "Warning",
    "getOppositeKiosk": "kiosque",
    "esdc": "esdc",
    "getOppositeESDC": "edsc",
    "accessible": "Accessible",
    "webGLTitle": "3D",
    "virtualSpace": "Virtual Space",
    "virtualSpacesPath": "virtual-spaces",
    "getOppositeVirtualSpacesPath": "espaces-virtuel",
    "nextKiosk": "Next Kiosk",
    "previousKiosk": "Previous Kiosk",
    "close": "Close",
    "startVisit": "Get Started",
    "openIntro": "Open introduction message",
    "openHint": "Open hint message",
    "bypassBlock":"Bypass Block",
    "skipToContent":"Skip to content",
    "warnNewWindow": "Link will open in a new tab.",
    "kioskExhibitionDescription": "Choose a kiosk to navigate to it.",
    "transcript": "Transcript",
    "openRelatedVideo": "Open related video",
    "relatedVideos": "Related videos",
    "titleWarnMovement" : "Welcome to vExpo!",
    "contentWarnMovement1" : "Explore and engage with all event material from one central location.",
    "contentWarnMovement2" : "Try the \"Exploration Mode\" for an immersive and interactive experience, using your mouse (or keyboard) to explore the lobby and interactive kiosks. Alternatively, use the \"List Mode\" for a more streamlined view.",
    "contentWarnMovement2Mobile": "Looking for a more immersive and interactive experience? Switch to a desktop computer to access the \"Exploration Mode\".",
    "listMode": "List Mode",
    "listModeDescription": "Simpler, direct to the point.",
    "listModeCTA": "Start Reading!",
    "listModeNotes": [
        "Recommended for accessibility",
        "Recommended if sensitive to motion sickness",
    ],
    "explorationMode": "Exploration Mode",
    "explorationModeDescription": "Richer visual experience.",
    "explorationModeCTA": "Start exploring!",
    "explorationModeNotes": [
        "Recommended for immersion",
        "Enjoy the event as if you were there!",
    ],
}

export default en;
