import React, { useEffect, useState } from 'react';
import { GCSplashScreen } from 'gc-tortilla';
import background from '../../assets/background.jpg';
import { useParams } from "react-router-dom";
import { fetchCurrentEventID, fetchEvent } from "../utils/FetchData";
import NotFound from './404';

const wordmarkFIP = {
    image: (process.env.PUBLIC_URL + "/Images/Fip/Canada_Wordmark_Colour_FIP.png")
};

const signatureFIP = {
    image: (process.env.PUBLIC_URL + "/Images/Fip/FIP_CSPS_EnCA.png")
}

// Language selection:
// Select language and redirect to event.
// If already set, redirect to event lobby set language
export default function LanguageSelection() {
    let { event_id } = useParams();

    // If pathname has esdcworkplace or milieudetravailedsc in it, or the event_id is esdc-edsc,
    // then we assume that it is ESDC event (2113)
    const esdcEvent = (
        !event_id && (
            window.location.pathname.includes("/esdcworkplace") ||
            window.location.pathname.includes("/milieudetravailedsc")
        ) || event_id === 'esdc-edsc'
    )

    let id = (esdcEvent ? '2113' : event_id)

    const [event, setEvent] = useState();

    useEffect(() => {
        if (!id) { return }

        fetchEvent({event_id: id}).then(result => { setEvent(result) })
    }, [id]);

    // Redirect to current event id
    if (!id) {
        fetchCurrentEventID()
            .then(result => { result && window.location.replace(`/#/${result}`) })
    }

    if (event?.event_id === '2113') {
        const ESDCRoutes = {
            english: "/en/#/explore/esdc/virtual-spaces/main",
            french: "/fr/#/explore/edsc/espaces-virtuel/main"
        }

        if (localStorage.langIsEnglish === "true") {
            window.location.replace(ESDCRoutes.english);
        }
        if (localStorage.langIsEnglish === "false") {
            window.location.replace(ESDCRoutes.french);
        }

        const signatureFIP = {
            image: (`${process.env.PUBLIC_URL}/${event.event_id}/${event.FIP.serviceTitlesImageURL}`)
        }

        return (
            <React.Fragment>
                <div className="backgroundImage" style={{ backgroundImage: `url(${background})` }} />
                <GCSplashScreen
                    backgroundImage={background}
                    routes={ESDCRoutes}
                    signatureFIP={signatureFIP}
                    wordmarkFIP={wordmarkFIP}
                />
            </React.Fragment>
        );
    }

    if (event === null) return <NotFound />

    if (!event) return null;

    const lobbyEn = `/en/#/${event.event_id}/lobby`
    const lobbyFr = `/fr/#/${event.event_id}/accueil`

    if (localStorage.langIsEnglish === "true") {
        window.location.replace(lobbyEn);
    }
    if (localStorage.langIsEnglish === "false") {
        window.location.replace(lobbyFr);
    }

    return (
        <React.Fragment>
            <div className="backgroundImage" style={{ backgroundImage: `url(${background})` }} />
            <GCSplashScreen
                backgroundImage={background}
                routes={{ english: lobbyEn, french: lobbyFr }}
                signatureFIP={signatureFIP}
                wordmarkFIP={wordmarkFIP}
            />
        </React.Fragment>
    );
}
