import React from 'react';
import styles from './BypassBlock.module.css';
import { HashLink as Link } from 'react-router-hash-link';


export default function BypassBlock(props) {


    return (
        <div className={styles.bypass}>
            <h2>{props.t.bypassBlock}</h2>
            <ul className={styles.blocklist}>
                <li>
                    <Link to={"#content"}>{props.t.skipToContent}</Link>
                </li>
            </ul>
        </div>
    );
}
