import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Add custom page view event to datalayer (Google Analytics)
export default function UsePageViews(props) {
    const location = useLocation();
    const title = props.title;
    const pagePath = "/" + props.locale + location.pathname;

    useEffect(() => {
        if (process.env.NODE_ENV !== 'production') return null

        window.dataLayer.push({
            event: 'pageview',
            page: {
                url: window.location.href,
                path: pagePath,
                title: title
            }
        });
    }, [pagePath, title]);

    return null;
}
