
/*
Slugify utility function to make a string URI-friendly.

Code from: https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1
Author permission: https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1#gistcomment-2964716
*/
export const slugify = (string) => {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')

    return string.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w-]+/g, '') // Remove all non-word characters
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
}

/**
 * Previously kiosk URLs were www.example.ca/#/{event code}/kiosks/{kiosk id}

 * URLs were updated to use the format www.example.ca/#/{event code}/kiosks/{kiosk id}-{kiosk title}

 * Returns kiosk path name: {kiosk id}-{kiosk title slug}
 * @param {object} kiosk object
*/
export const slugifyKioskTitle = (kiosk) => {
    return `${kiosk.vKioskID}-${slugify(kiosk.title)}`
}
