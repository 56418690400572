export default function interpolate({message, params = {}, count = 1}) {
  const interpolated = Object.keys(params).reduce((interpolated, key) =>
    interpolated.replace(
      new RegExp(`:s*${key}s*`, "g"),
      params[key],
    ),
    message,
  );
  return interpolated.replace(
    /((\p{L}+)\|(\p{L}+))/gu,
    count > 1 ? '$3' : '$2'
  );
}