import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Unity, useUnityContext } from "react-unity-webgl";
import styles from './vSpacePlayer.module.css';
import './WebGLUnity.css';

export default function VSpacePlayer(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const canvasRef = useRef(null);

    const { unityProvider, loadingProgression, addEventListener, removeEventListener, sendMessage } = useUnityContext({
        loaderUrl: "/vSpacePlayer/Build/vSpacePlayer.loader.js",
        dataUrl: "/vSpacePlayer/Build/vSpacePlayer.data.br",
        frameworkUrl: "/vSpacePlayer/Build/vSpacePlayer.framework.js.br",
        codeUrl: "/vSpacePlayer/Build/vSpacePlayer.wasm.br",
        streamingAssetsUrl: "StreamingAssets",
        companyName: "CSPS",
        productName: "2100_Virtual Expo",
        productVersion: "0.1"
    });

    const loadingPercentage = Math.round(loadingProgression * 100);

    // GA vSpaceLoaded event.
    function vSpaceLoadedEvent() {
        sendMessage('Application', 'EnableGA', 'true');
        console.log('Google analytics is ready');
        window.dataLayer.push({
            event: 'loadedvSpace',
            customEvent: {
                // Hardcode S2113 as the id as ESDC is the only one using vSpace player right now.
                label: 'S2113'
            },
            page: {
                path: window.location.pathname + window.location.hash,
                url: window.location.href
            }
        });
    }

    useEffect(() => {
        if (!isLoaded) return

        // vSpace load space
        function loadSpace() {
            sendMessage('UI', 'NativateToVirtualSpace', props.space.vSpaceID);
            sendMessage('UITK', 'SetUILabelText', `BackButtonBG#${props.t.back}`);
            sendMessage('UITK', 'SetUILabelText', `InfoButtonBG#${props.t.info}`);
            sendMessage('UITK', 'SetUILabelText', `MapButtonBG#${props.t.map}`);
        }

        loadSpace();
    }, [isLoaded, props.space.vSpaceID]);

    useEffect(() => {
        window.alert = console.log

        return function cleanup(){
            window.location.reload()
        }
    }, []);

    // Manage to resume if a modal is closed
    useEffect(() => {
        if (!isLoaded) return;

       // If a modal is opened, pause, otherwise resume.
       sendMessage('Application', props.openedModal ? 'SetPauseState' : 'SetResumeState');
    }, [isLoaded, props.openedModal]);

    // Manage what happens when the player is loaded
    // Enable the GA event.
    useEffect(() => {
        if (!isLoaded) { return }

        vSpaceLoadedEvent();
    }, [isLoaded]);

    // CanvasRef to resize
    useEffect(() => {
        if (!canvasRef.current) return

        canvasRef.current.width = 4096
        canvasRef.current.height = 2048
    }, [canvasRef]);

    const VirtualSpacesPlayerSetupEvent = useCallback(() => {
        const lang = (props.t.getLocale === "en" ? "EnCA" : "FrCA")

        sendMessage('Application', 'SetApplicationLogging', 'true');
        sendMessage('Application', 'SetLanguage', lang);

        sendMessage('UITK', 'LoadAPIData');
    }, [sendMessage]);

    const VirtualSpacesPlayerBtnClickEvent = useCallback((buttonName) => {
        if (buttonName === "Info Btn Callback") {
            // Open Intro message modal
            props.openIntroModal();
        } else if (buttonName === "Back Btn Callback") {
            // Back to previous space
            props.backToPreviousSpace();
        } else if (buttonName === "Map Btn Callback") {
            // Open Map Modal
            props.openMapModal();
        }
    }, [props]);

    const VirtualSpacesPlayerNavigateToSpaceEvent = useCallback((vSpaceID) => {
        props.navigateToSpace(vSpaceID);
    }, [props]);

    const VirtualSpacesPlayerOpenVideoPlayerEvent = useCallback((contentID) => {
        props.openVideoPlayer(contentID);
    }, [props]);

    const VirtualSpacesPlayerOpenKioskSelectionEvent = useCallback(() => {
        props.toggleModalTablet(true);
    }, [props]);

    const VirtualSpacesPlayerStartEvent = useCallback(() => {
        setIsLoaded(true);
    }, []);

    useEffect(() => {
        addEventListener("VirtualSpacesPlayerSetupEvent", VirtualSpacesPlayerSetupEvent)
        addEventListener("VirtualSpacesPlayerBtnClickEvent", VirtualSpacesPlayerBtnClickEvent)
        addEventListener("VirtualSpacesPlayerNavigateToSpaceEvent", VirtualSpacesPlayerNavigateToSpaceEvent)
        addEventListener("VirtualSpacesPlayerOpenVideoPlayerEvent", VirtualSpacesPlayerOpenVideoPlayerEvent)
        addEventListener("VirtualSpacesPlayerOpenKioskSelectionEvent", VirtualSpacesPlayerOpenKioskSelectionEvent)
        addEventListener("VirtualSpacesPlayerStartEvent", VirtualSpacesPlayerStartEvent)

        return () => {
            removeEventListener("VirtualSpacesPlayerSetupEvent", VirtualSpacesPlayerSetupEvent);
            removeEventListener("VirtualSpacesPlayerBtnClickEvent", VirtualSpacesPlayerBtnClickEvent)
            removeEventListener("VirtualSpacesPlayerNavigateToSpaceEvent", VirtualSpacesPlayerNavigateToSpaceEvent)
            removeEventListener("VirtualSpacesPlayerOpenVideoPlayerEvent", VirtualSpacesPlayerOpenVideoPlayerEvent)
            removeEventListener("VirtualSpacesPlayerOpenKioskSelectionEvent", VirtualSpacesPlayerOpenKioskSelectionEvent)
            removeEventListener("VirtualSpacesPlayerStartEvent", VirtualSpacesPlayerStartEvent)
        };
    }, [
        addEventListener,
        removeEventListener,
        VirtualSpacesPlayerSetupEvent,
        VirtualSpacesPlayerBtnClickEvent,
        VirtualSpacesPlayerNavigateToSpaceEvent,
        VirtualSpacesPlayerOpenVideoPlayerEvent,
        VirtualSpacesPlayerOpenKioskSelectionEvent,
        VirtualSpacesPlayerStartEvent
    ]);

    let unityLoadingClasses = [styles.unityLoading, isLoaded ? styles.loaded : ''];

    return (
        <div className={styles.webGLContainer}>
            <div id="unity-container" className={styles.unityContainer}>
                <Unity unityProvider={unityProvider} className={styles.unityCanvas} ref={canvasRef} matchWebGLToCanvasSize={false}/>
                <div id="unity-loading" className={unityLoadingClasses.join(' ')}>
                    <div id="unity-loading-bar" className={styles.unityLoadingBar}>
                        <div className="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <div id="unity-progress-bar-empty">
                            <div id="unity-progress-bar-full" style={{ width: loadingPercentage + "%"}}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
