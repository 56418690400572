import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Lobby from './components/pages/Lobby';
import Kiosk from './components/pages/Kiosk';
import dictionary from './dictionaries/en';
import LanguageSelection from "./components/pages/LanguageSelection";
import ScrollToTop from "./components/utils/ScrollToTop";
import MainPresentation from "./components/pages/MainPresentation";
import ESDCSpace from './components/pages/ESDCVirtualSpace';
import notFound from './components/pages/404';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material';

export default function App() {
    let theme = createTheme();
    theme = responsiveFontSizes(theme);

    return (
        <HashRouter>
            <ThemeProvider theme={theme}>
                <ScrollToTop />
                <Switch>
                    {/* Redirecting Blank URLs to language selection page*/}
                    <Route exact path="/">
                        <LanguageSelection />
                    </Route>

                    {/* Redirecting event to language selection */}
                    <Route exact path="/:event_id">
                        <LanguageSelection />
                    </Route>

                    <Route exact path={`/explore/${dictionary.esdc}/${dictionary.virtualSpacesPath}/:id`}>
                        <ESDCSpace t={dictionary} />
                    </Route>

                    <Route exact path="/:id/lobby">
                        <Lobby t={dictionary} />
                    </Route>

                    <Route exact path={[`/:event_id/${dictionary.kiosk}`, `/:event_id/${dictionary.kiosk}/:id`]}>
                        <Kiosk t={dictionary} />
                    </Route>

                    <Route exact path={`/:event_id/${dictionary.mainPresentationPath}`}>
                        <MainPresentation t={dictionary} />
                    </Route>

                    <Route path="*" component={notFound} />
                </Switch>
            </ThemeProvider>
        </HashRouter>
    );
}
