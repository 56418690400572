import React, {useState} from 'react';
import styles from './MobileSpace.module.css';
import VideoPlayer from "../organisms/VideoPlayer";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

const leftBorderColors = ["#FCB65A", "#7AA9C2", "#89C169", "#94C1B5", "#EE522C"];

export default function MobileSpace(props) {
    const image = (process.env.PUBLIC_URL + "/2113/mobile/" + props.space.bannerImageURLMobile);
    const [videoState, setVideoState] = useState(defaultVideoState());

    function defaultVideoState() {
        const videos = props.space.contentItems.filter(
            content => (content.linkURL && content.linkURL.split('|')[0] === "video")
        )

        return videos.map(content => content.contentItemID)
                     .reduce((acc, key) => {acc[key] = false; return acc; }, {})
    }

    function handleClick(e) {
        let stateDup = {...videoState};
        stateDup[e] = true;

        setVideoState(stateDup)
    }

    function closeVideo() {
        setVideoState(defaultVideoState());
    }

    function modalVideoLink(content){
        return (
            <li key={content.contentItemID}>
                <button onClick={() => handleClick(content.contentItemID)}>
                    {content.titleMobile}
                </button>
                <VideoPlayer
                    open={videoState[content.contentItemID]}
                    content={content}
                    toggleModal={closeVideo}
                    mobile={true}
                    t={props.t} />
            </li>
        )
    }

    function contentLink(content){
        if (content.linkURL && content.linkURL.split('|')[0] === 'video'){
            return modalVideoLink(content)
        } else {
            return (
                <li key={content.titleMobile}>
                    <a href={content.linkURL} target="_blank" rel="noreferrer">
                        {content.titleMobile}
                    </a>
                </li>
            )
        }
    }

    function contentList(){
        if (props.space.vSpaceID === 'S211301') {
            return (
                <ul className={styles.linksList} >
                    {props.space.contentItems.map((content, index) => (
                        <li key={content.titleMobile}
                            className={styles.leftBorder}
                            style={{borderLeftColor: leftBorderColors[index % leftBorderColors.length]}}>
                            <a href={content.linkURL} target="_blank" rel="noreferrer">{content.titleMobile}</a>
                            <p>{content.description}</p>
                        </li>
                    ))}
                </ul>
            )
        }

        return (
            <ul className={styles.videoList} >
                {props.space.contentItems.map((content) => (
                    contentLink(content)
                ))}
            </ul>
        )
    }

    return (
        <div className={styles.spaceContainer}>
            <img src={image} alt=""/>
            {props.space.description &&
                <React.Fragment>
                    <div className={styles.description}>
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                            {props.space.description}
                        </ReactMarkdown>
                    </div>
                </React.Fragment>
            }
            { contentList() }
        </div>
    );
}
