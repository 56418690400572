import React, { useCallback, useEffect, useState } from 'react';
import { slugifyKioskTitle } from '../utils/KioskTitleSlugify';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import { throttle } from 'throttle-debounce';
import interpolate from '../utils/interpolate';
import styles from './KioskExhibition.module.css';

export default function KioskExhibition(props) {
  const [searchResults, setSetSearchResults] = useState(props.kiosks);
  const [searchValue, setSearchValue] = useState('');
  const [lockKioskSelection, setLockKioskSelection] = useState(false);

  function changeQuery(event) {
    setSearchValue(event.target.value);
    searchThrottled(event.target.value);
  }

  function search(query) {
    if (query.length === 0) {
      // reset to show all kiosks when user clears the input
      setSetSearchResults(props.kiosks);
      return;
    }
    const searches = props.kiosks.filter(kiosk => kiosk.title.toLowerCase().includes(query.toLowerCase()));
    setSetSearchResults(searches);
  }

  const searchThrottled = throttle(500, search);

  const resetState = useCallback(() =>{
    setSearchValue('');
    // reset search results
    setSetSearchResults(props.kiosks);
  }, [props.kiosks]);

  function isKioskDisabled(vKioskID) {
    return lockKioskSelection || isCurrentKioskSelected(vKioskID);
  }

  function isCurrentKioskSelected(vKioskID) {
    return props.kiosk?.vKioskID === vKioskID;
  }

  function getCardClasses(kiosk) {
    let cardClasses = [styles.card, isCurrentKioskSelected(kiosk.vKioskID) ? styles.kioskDisabled : ''];

    return cardClasses.join(' ');
  }

  // Reset State when kiosks change.
  useEffect(() => {
        resetState()
    }, [resetState]
  );

  // Lock kiosk selection when a kiosk is selected, and reset state.
  function lockAndResetState(){
    if (lockKioskSelection) return

    setLockKioskSelection(true);
    resetState();
  }

  return (
    <div className={`${styles.content} ${props.isMobile? styles.isMobile:""}`}>
        <div className={styles.header}>
        <h2>{ props.t.exhibitionGuide.toUpperCase() }</h2>
        <Paper
            component="form"
            className={styles.search}
        >
            <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={props.t.search}
            inputProps={{ 'aria-label': props.t.search }}
            value={searchValue}
            onChange={changeQuery}
            type="search"
            />
            <SearchIcon />
        </Paper>
        <Typography className={styles.searchResults}>
            {searchResults.length > 0 ?
            searchResults.length !== props.kiosks.length ?
                interpolate({
                message: props.t.kiosksFound,
                params: {
                    num: searchResults.length
                },
                count: searchResults.length
                })
            : interpolate({
                message: props.t.kiosksTotal,
                params: {
                    num: searchResults.length
                },
                count: searchResults.length
                })
            : props.t.noKioskFound
            }
        </Typography>
        </div>
        <div className={styles.gridWrapper}>
        <Grid container columnSpacing={{ md: 2, lg: 3 }} rowSpacing={{ md: 2, lg: 3 }} component='ul'>
            {searchResults.map((kiosk, i) => {
            return <Grid key={kiosk.vKioskID} item xs={12} sm={6} md={6} lg={6} xl={4} className={styles.gridItem}  component='li'>
                <Card className={getCardClasses(kiosk)} raised>
                
                  <CardActionArea
                      disableTouchRipple={isKioskDisabled(kiosk.vKioskID)}
                      disableRipple={isKioskDisabled(kiosk.vKioskID)}
                      disabled={isKioskDisabled(kiosk.vKioskID)}
                      href={`#/${props.event_id}/${props.t.kiosk}/${slugifyKioskTitle(kiosk)}`}
                      onClick={() => lockAndResetState()}
                      className={styles.cardActionArea}
                  >
                      <div className={styles.vignette}>
                      <CardMedia
                          component="img"
                          height="140"
                          image={kiosk.thumbnailImageUrl || kiosk.iconImageURLMobile}
                          className={styles.cardMedia}
                          alt=""
                      />
                      </div>
                        <CardContent className={styles.cardContent}>
                          <Tooltip title={kiosk.title} placement="top">
                            <Typography gutterBottom variant="subtitle1" component="div" align="center" className={styles.cardContentText}>
                              {kiosk.title}
                            </Typography>
                          </Tooltip>
                      </CardContent>
                  </CardActionArea>
                
                </Card>
            </Grid>
            })}
        </Grid>
        </div>
    </div>
  );
}
