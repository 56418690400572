import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ReactMarkdown from 'react-markdown'
import styles from './HelpHintModal.module.css'

const buttonStyle = {
    backgroundColor: '#345074',
    color: 'white',
    boxShadow: '0 4px 1px #000000',
    width: '250px',
    borderRadius: '8px',
    lineHeight: '2',
    fontSize: '20px',
    fontFamily: 'Lato, sans-serif',
    textTransform: 'none',
    ':hover': { backgroundColor: '#345074'}
}

export default function HelpHintModal(props) {
    const [open, setOpen] = React.useState(props.show);

    function handleClose() {
        props.parentCallback && props.parentCallback();
        setOpen(false);
    }

    return (
        <Dialog onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                maxWidth="md"
                open={open}
                PaperProps={{style: { borderRadius: "50px 5px 5px 5px", maxWidth: "600px"}}}>
            <DialogTitle id="customized-dialog-title" t={props.t} sx={{padding: "10px 10px"}}>
                {open ? (
                    <IconButton aria-label={props.t.close} onClick={handleClose} sx={{position: 'absolute', right: '8px', top: '8px'}}>
                        <CloseIcon fontSize={'large'} ></CloseIcon>
                    </IconButton>
                ) : null}
                <img src={`/2113/icons/${props.vspace.helpHintIconURL}`} alt={""} />
            </DialogTitle>
            <DialogContent sx={{color: '#4F5B72', fontFamily: 'Lato, sans-serif', padding: "0 24px"}}>
                <ReactMarkdown className={styles.hintModal}>
                    {
                        props.vspace.helpHint
                    }
                    </ReactMarkdown>
            </DialogContent>
            <DialogActions sx={{padding: '20px', justifyContent: 'center'}}>
                <Button onClick={handleClose} sx={buttonStyle}>
                    { props.t.close }
                </Button>
            </DialogActions>
        </Dialog>
    );
}
