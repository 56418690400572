import {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import axios from "axios";

// For dynamic main presentation link: call the api for the event,
// if a main presentation url is present, redirect to it.
export default function MainPresentation(props) {
    let { event_id } = useParams();

    const [mainPresentationLink, setMainPresentationLink] = useState();

    useEffect(() => {
        const url = `events/${event_id}/main-presentation?lang=${props.t.getLocale}`;
        // Disable browser cache for api.
        const options = { headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0' } }

        axios.get(url, options)
            .then(response => {
                setMainPresentationLink(response.data.url)
            }).catch(function (error) {
                console.log(error.message);
                setMainPresentationLink("/2110/Docs/Collaborate_Video.html")
        });
    }, [event_id, props.t.getLocale]);

    if (!mainPresentationLink) {
        return null
    }

    window.location.replace(mainPresentationLink);
}
