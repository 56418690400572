import axios from "axios";

// Fetch event
// Try to find the event in file using locale and event_id prop first.
// If not found, fetch from the api (vWebsites) using the same props.
// Return undefined if the event is not found, or the api call failed
export async function fetchEvent(props){
    if (props.event_id === '2113') { return fetchESDCEvent(props) }

    let url = `vwebsites/${props.event_id}`;

    if (props.locale) { url += `?lang=${props.locale}`}

    let data = await fetchData(url)

    // If the event is not found for 2301, try to fetch it from the file.
    if (!data && props.event_id === '2301') {
        data = await fetchStaticData(`/2301/Docs/2301_${props.locale || 'en'}.json`)
    }

    if (!data) return null;

    return {
        event_id: data.vWebsiteID,
        event_title: data.title,
        courseCode: data.courseCode,
        svgURL: data.vSpaces[0].svgURL,
        lobby: data.vSpaces[0].contentItems,
        kiosks: data.vKiosks
    };
}

async function fetchESDCEvent(props){
    const data = await fetchData(`vwebsites/${props.event_id}?lang=${props.locale || 'en'}`)

    if (!data) return null;

    return {
        event_id: data.vWebsiteID,
        event_title: data.title,
        kiosks: data.vKiosks,
        FIP: data.FIP
    };
}

export async function fetchESDCSite(props){
    return await fetchData(`vwebsites/2113?lang=${props.locale || 'en'}`)
}

// Get current event id from the api.
export async function fetchCurrentEventID() {
    const data = await fetchData('configuration/current_event_id')

    // ONLY FOR GC DATA EVENT: if current_event_id not found or error, return 2301.
    if (!data) return '2301';

    return data?.currentEventID;
}

// Fetch kiosk from api first, then from file if the kiosk is not found (or the api call failed).
export async function fetchKiosk(props){
    const url = `kiosks/${props.kiosk_id}?lang=${props.locale}&version=mobile&draft=${props.draft}`;

    return await fetchData(url)
}

// Return response data, or null if error,
async function fetchData(url){
    try {
        const response = await axios.get(url)

        return response.data
    } catch (error) {
        console.error(error)

        return null
    }
}

// Return response data, or null if error,
async function fetchStaticData(url){
    try {
        const response = await axios.get(url, { baseURL: '' })

        return response.data
    } catch (error) {
        console.error(error)

        return null
    }
}
