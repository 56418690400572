import React from 'react';
import styles from './TopBar.module.css';
import TopBarMobile from "../organisms/TopBarMobile";
import IconButton from '@mui/material/IconButton';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useHistory } from "react-router-dom";
import { WebGLVersionAvailable } from "../utils/CompatibilityHelper";
import { useWindowWidth } from "window-dimensions-hooks";
import { Tooltip } from '@mui/material';
import { setListMode, setExplorationMode, isListMode } from '../utils/CompatibilityHelper';

export default function TopBar(props) {
    const history = useHistory();

    const width = useWindowWidth();

    function switchToListMode() {
        setListMode();

        history.go(0);
    }
    function switchToExplorationMode() {
        setExplorationMode();

        history.go(0);
    }

    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        },
    });

    function versionButton(props) {
        return (
            <Tooltip title={props.label}>
                <IconButton
                    onClick={props.onClick}
                    tabIndex={0}
                    aria-label={props.label}
                    disableRipple
                    sx={{
                        borderRadius: 0,
                        transition: theme => theme.transitions.create(['background-color']),
                        '&:hover': {
                            backgroundColor: '#333333',
                        },
                    }}
                    size="large"
                >
                    {props.icon}
                </IconButton>
            </Tooltip>
        );
    }

    function switchVersionButton() {
        // No button to switch version, since in accessible mode.
        if (!WebGLVersionAvailable(width, props.noWidthLimit)) return

        if (!isListMode()) {
            return versionButton({
                label: props.t.listMode,
                icon: <ListAltOutlinedIcon fontSize="inherit" />,
                onClick: switchToListMode,
            });
        }

        // Otherwise, in accessible view so show the 3d button
        return versionButton({
            label: props.t.explorationMode,
            icon: <ViewInArOutlinedIcon fontSize="inherit" />,
            onClick: switchToExplorationMode,
        });
    }

    // Return opposite language url language for path.
    function getOppositeLangPath(path) {
        return props.t.getOppositeLocale === "fr" ? `/fr/#/${path}` : `/en/#/${path}`
    }

    function getOppositeURL() {
        // If the window is on the kiosks page.
        // BIG NOTE: Using router to go from /kiosk/kiosk_ID to /kiosk/kiosk_ID-Kiosk-Title
        if (window.location.hash.includes(`/${props.t.kiosk}/`)) {
            const kioskText = (props.t.getOppositeLocale === "fr" ? "kiosque" : "kiosk")
            const eventId = props.event_id || "2100"
            const path = `${eventId}/${kioskText}`
            if (!props.kiosk) { return path }

            const oppositeKioskPath = getOppositeLangPath(`${path}/${props.kiosk.vKioskID}`)

            if(window.location.hash.includes("draft=true")){
                return `${oppositeKioskPath}?draft=true`
            }

            return oppositeKioskPath;
        }

        // For ESDC virtual spaces.
        if (props.space) {
            const oppositeSpacePath = `explore/${props.t.getOppositeESDC}/${props.t.getOppositeVirtualSpacesPath}/${props.space.vSpaceID}`
            if(window.location.hash.includes("subpage=kiosklist")){
                return getOppositeLangPath(`${oppositeSpacePath}/?subpage=kiosklist`)
            }

            return getOppositeLangPath(oppositeSpacePath)
        }

        const lobbyText = (props.t.getOppositeLocale === "fr" ? "accueil" : "lobby")
        if(window.location.hash.includes("subpage=kiosklist")){
            return getOppositeLangPath(`${props.event_id}/${lobbyText}/?subpage=kiosklist`);
        }
        return getOppositeLangPath(`${props.event_id}/${lobbyText}`);
    }

    function headerTitle() {
        if (!props.title) return;

        // Add vExpo prefix only when not ESDC.
        return (props.event_id === '2113') ? props.title : `vExpo - ${props.title}`;
    }

    function addMobileTopBar() {
        if (props.mobile) {
            return (
                <TopBarMobile
                    t={props.t}
                    title={headerTitle()}
                    main={props.main}
                    space={props.space}
                    event_id={props.event_id}
                    kiosk={props.kiosk}
                    vSite={props.vSite}
                    kioskList={props.kioskList}
                    setWelcomeModalOpen={props.setWelcomeModalOpen}
                />
            )
        }
    }

    return (
        <header>
            <div className={`topBar ${styles.topBar} ${props.mobile?styles.isMobile:""}`}>
                <h1 className={styles.title}>
                    {headerTitle()}
                </h1>

                <div className={styles.right}>
                    <ThemeProvider theme={darkTheme}>
                        {switchVersionButton()}
                    </ThemeProvider>
                    <Tooltip title={props.t.getOppositeLang}>
                        <IconButton
                            className={styles.languageToggle}
                            component="a"
                            href={getOppositeURL()}
                            lang={props.t.getOppositeLocale}
                            disableRipple
                            sx={{
                                borderRadius: 0,
                                color: 'white',
                                fontSize: 'inherit',
                                p: 0,
                                transition: theme => theme.transitions.create(['background-color']),
                                '&:hover': {
                                    backgroundColor: '#333333',
                                },
                            }}
                        >
                            {props.t.getOppositeLocale.toUpperCase()}
                        </IconButton>
                    </Tooltip>
                </div>
            </div>

            {addMobileTopBar()}
        </header>
    );
}
