import React from 'react';
import NavLink from '../organisms/NavLink';
import styles from './Mobile.module.css';
import IconText from '../organisms/IconText';
import { incompatibleBrowserFor3DVersion } from "../utils/CompatibilityHelper";

export default function MobileKiosk(props) {
    const rowGroups = props.kiosk.rows

    function renderKioskContent() {
        // Array to hold the output that will be rendered
        let output = [];

        // If the browser is an unsupported browser it display the incompatible browser message to tell the user how to view the 3d view
        if (incompatibleBrowserFor3DVersion()) {
            output.push(
                <a href={props.t.incompatibleBrowserUrl} key={props.t.incompatibleBrowser + "_card"} target="_blank" rel="noreferrer" className={styles.warnBrowser}>
                    <span className="invisible">{ props.t.warnNewWindow }</span>
                    <IconText t={props.t} icon='warning' key="warning">
                        <p>{props.t.incompatibleBrowserMessage}</p>
                    </IconText>
                </a>
            );
        }

        // Output the gc network info
        output.push(
            <IconText t={props.t} icon='info' key="info" center><p>{props.t.gcNetworkInfo}</p></IconText>
        )
        //output the kiosk title
        output.push(
            <h2 id="kiosk_title" key={"kiosk_title"} className={styles.kioskTitle}>{props.kiosk.title}</h2>
        )

        // Loop through data, assign current object to section
        rowGroups.forEach(group => {

            // Output the title from Section if present
            let title = group.text
            if (title) { output.push(<h3 key={title}>{title}</h3>) }

            // Loop through section.content, assign current object to item
            group.contentItems.forEach((item, itemIndex) => {
                if (item.linkURL) {
                    output.push(
                        <NavLink data={item} warning={props.t.warnNewWindow} key={ item.description } url={item.linkURL}/>
                    );
                } else {
                    output.push(
                        <NavLink data={item} key={itemIndex} />
                    );
                }

            });
        });

        return output;
    }

    function displayBanner(){
        const jpgThumbnail = props.kiosk.bannerImageURLMobile;

        if(props.lobby!=="kiosklist"){
            return (<div style={{backgroundImage: `url(${jpgThumbnail})`}} className={styles.bgImage}></div>);
        }
    }    

    return (
        <div className={styles.mobile}>
            <main className={styles.mainContainer} id="content">
                <nav className={styles.navList}>
                    { displayBanner() }
                    { renderKioskContent() }
                </nav>
            </main>
        </div>
    );    
}
