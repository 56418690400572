const fr = {
    "getLocale": "fr",
    "getOppositeLocale": "en",
    "getOppositeLang": "English",
    "backToLobby": "Retour à l'accueil",
    "back": "Retour",
    "info": "À propos du projet",
    "map": "Carte",
    "headerTitle": "vExpo de l'EFPC (Beta)",
    "search": "Rechercher",
    "Lobby": "Accueil",
    "Kiosks": "Kiosques",
    "kiosk": "kiosque",
    "KioskExhibition":"Guide de l'exposition",
    "kiosksFound": ":num kiosque|kiosques trouvé|trouvés",
    "kiosksTotal": ":num kiosque|kiosques au total",
    "noKioskFound": "Aucun kiosque trouvé",
    "exhibitionGuide": "Guide de l'exposition",
    "mainPresentationPath": "presentation-principale",
    "cspsKiosksTitle": "Apprenez au sujet de l'École de la fonction publique du Canada.",
    "incompatibleBrowser": "Navigateur non supporté",
    "incompatibleBrowserMessage": "Il semble que votre navigateur ne supporte pas les technologies requises pour afficher le kiosque 3D. Changez de navigateur pour profiter pleinement de l'expérience.",
    "incompatibleBrowserUrl": "https://wiki.gccollab.ca/Acad%C3%A9mie_du_num%C3%A9rique_de_l%E2%80%99%C3%89FPC/Compatibilit%C3%A9_des_navigateurs_pour_l%E2%80%99Expo_virtuel",
    "gcNetworkInfo": "Certains liens peuvent n'être accessibles que sur les réseaux du GC.",
    "warning": "Avertissement",
    "getOppositeKiosk": "kiosk",
    "esdc": "edsc",
    "getOppositeESDC": "esdc",
    "accessible": "Accessible",
    "webGLTitle": "3D",
    "virtualSpace": "Espace Virtuel",
    "virtualSpacesPath": "espaces-virtuel",
    "getOppositeVirtualSpacesPath": "virtual-spaces",
    "nextKiosk": "Prochain Kiosque",
    "previousKiosk": "Kiosque Précédent",
    "close": "Fermer",
    "startVisit": "Commencez votre visite",
    "openIntro": "Ouvrir le message d'introduction",
    "openHint": "Ouvrir l'aide contextuelle",
    "bypassBlock":"Bloc de contournement",
    "skipToContent":"passer au contenu",
    "warnNewWindow": "Le lien s'ouvrira dans un nouvel onglet.",
    "kioskExhibitionDescription": "Choisissez un kiosque pour y accéder.",
    "transcript": "Transcription",
    "openRelatedVideo": "Ouvrir la vidéo connexe",
    "relatedVideos": "Vidéos connexes",
    "titleWarnMovement" : "Bienvenue à vExpo !",
    "contentWarnMovement1" : "Explorez et consultez l'ensemble du matériel de l'événement à partir d'un point central.",
    "contentWarnMovement2" : "Essayez le « mode exploration » pour une expérience immersive et interactive, en utilisant votre souris (ou votre clavier) pour explorer la salle d'accueil et les kiosques interactifs. Vous pouvez également utiliser le « mode liste » pour une vue simplifiée.",
    "contentWarnMovement2Mobile": "Vous souhaitez une expérience plus immersive et interactive ? Passez à un ordinateur de bureau pour accéder au « mode exploration ».",
    "listMode": "Mode liste",
    "listModeDescription": "Plus simple, direct à l'essentiel.",
    "listModeCTA": "Commencez à lire !",
    "listModeNotes": [
        "Recommandé pour l'accessibilité",
        "Recommandé si vous êtes sensible au mal des transports",
    ],
    "explorationMode": "Mode exploration",
    "explorationModeDescription": "Expérience visuelle plus riche.",
    "explorationModeCTA": "Commencez à explorer !",
    "explorationModeNotes": [
        "Recommandé pour l'immersion",
        "Profitez de l'événement comme si vous y étiez!",
    ],
}

export default fr;
