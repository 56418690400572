import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import styles from "./VideoPlayer.module.css"
import CloseIcon from "@mui/icons-material/Close";
import PlayIcon from '@mui/icons-material/PlayCircleOutline';
import Plyr from 'plyr-react'
import 'plyr-react/dist/plyr.css'
import i18nFr from "../../dictionaries/videoPlayerFr";
import ReactMarkdown from 'react-markdown'
import axios from 'axios';

const defaultOptions = {
    'controls': [
        'play-large',
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'captions',
        'settings',
        'airplay',
        'fullscreen'
    ]
}

function VideoPlayer(props) {
    const { open, toggleModal } = props;
    const showRelatedVideos = (props.videoList && props.videoList.length > 1)

    const [currentVideo, setCurrentVideo] = useState(contentToVideoItem(props.content));
    const [transcript, setTranscriptText] = useState();
    const [source, setSource] = useState();

    useEffect(() => {
        setSource({
            type: "video",
            title: currentVideo.title,
            sources: [{src: currentVideo.source, type: 'video/mp4'}],
            tracks: [{
                kind: 'captions',
                src_lang: props.t.getLocale,
                src: currentVideo.captionURL
            }],
            poster: currentVideo.imageURL
        })

        axios.get(process.env.PUBLIC_URL + currentVideo.transcriptURL, { baseURL: '' })
         .then((response) => { setTranscriptText(response.data) })
    }, [currentVideo, props.t.getLocale]);

    useEffect(() => {
        // Add customEvent for when a video is opened.
        if (!open || !currentVideo) return;

        window.dataLayer.push({
            event: props.mobile ? 'clickModalVideoMobile' : 'clickModalVideo3D',
            customEvent: {
                // Title
                label: currentVideo.title
            },
            page: {
                path: window.location.pathname + window.location.hash,
                url: window.location.href
            }
        });
    }, [open, currentVideo, props.mobile]);

    let options = defaultOptions

    if (props.t.getLocale === "fr"){
        options["i18n"] = i18nFr
    }

    function handleCLose(){
        // Reset current video
        setCurrentVideo(contentToVideoItem(props.content))

        toggleModal()
    }

    function contentToVideoItem(content){
        return {
            source: content.linkURL.split('|')[1],
            transcriptURL: `/2113/video/${content.contentItemID}/transcript_${props.t.getLocale}.txt`,
            captionURL: `/2113/video/${content.contentItemID}/${props.t.getLocale}.vtt`,
            imageURL: content.iconImageURL,
            title: content.titleMobile,
            contentItemID: content.contentItemID
        }
    }

    function changeVideo(content){
        setCurrentVideo(contentToVideoItem(content))
    }

    // Remove current video for videoList
    function relatedVideos(){
        return props.videoList.filter(content => (content.contentItemID !== currentVideo.contentItemID))
    }

    function relatedVideoLinks(){
        return (
            <div className={styles.relatedVideos}>
                <h3>{currentVideo.title}</h3>

                <div className={styles.relatedVideosContainer}>
                    <p>{props.t.relatedVideos}:</p>
                    {relatedVideos().map((video) => (
                        <button onClick={() => changeVideo(video)}
                                key={video.titleMobile}
                                aria-label={ `${props.t.openRelatedVideo}: ${video.titleMobile}` }
                                title={video.titleMobile}
                                className={styles.relatedVideoButton}>
                            <img src={video.iconImageURL} className={styles.relatedVideoThumbnail}/>
                            <PlayIcon fontSize={'large'} style={{"position": "absolute", "top": "30%", "left": "35%"}}/>
                        </button>
                    ))}
                </div>
            </div>
        )
    }

    return (
        <Modal
            open={open}
            className={styles.modal}
            onClose={toggleModal}
        >
            <div className={styles.videoInner}>
                <div className={styles.videoWrapper}>
                    <button onClick={handleCLose} className={styles.closeButton} aria-label={ props.t.close }>
                        <CloseIcon fontSize={'large'} style={{color: "white"}}/>
                    </button>

                    <div className={styles.plyrWrapper}>
                        <Plyr source={source} options={options} width="100%" height="100%" {...{crossOrigin: "true"}}/>
                    </div>
                    {showRelatedVideos && relatedVideoLinks()}

                    <details className={styles.transcript}>
                        <summary>{props.t.transcript}</summary>

                        <ReactMarkdown className={ styles.transcriptText }>
                            {transcript}
                        </ReactMarkdown>

                    </details>
                </div>

            </div>
        </Modal>
    );
}

export default VideoPlayer;
