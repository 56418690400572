import React from 'react';
import styles from './IconText.module.css';


export default function IconText(props) {
    const imgArray = {
        "warning":"warning_icon.png",
        "info" : "info_icon.png"
    };
    let bgImage="";
    if(typeof props.url !== "undefined"){
        bgImage=props.url;
    }else if(typeof props.icon !=="undefined"){
        bgImage="/Images/Icons/"+imgArray[props.icon];
    }else{
        
        bgImage="/Images/Icons/"+imgArray["warning"];
    }
    
    return (
        <div className={`${styles.IconText} ${props.center ? styles.center : ""}`}>
            <i style={{backgroundImage: `url('${bgImage}')`}}></i>
            <span>{props.children}</span>
        </div>
    );
}
