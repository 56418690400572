import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Slide from '@mui/material/Slide';

import styles from './ModalTablet.module.css';

export default function ModalTablet(props) {

  function handleClose() {
    props.toggleModalTablet(false);
  }

  // @note: disableEnforceFocus allows us to be able to
  //        tab through other elements of the DOM when the modal is shown.
  //        This is necessary in order to gain access to the topBar.
  return (
    <Modal
      disableEnforceFocus
      open={props.isModalShown}
      onClose={(handleClose)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        invisible: true,
        className: styles.backdrop
      }}
      className={`${styles.modal} ${props.isMobile?styles.isMobile:styles.notMobile}`}
      aria-labelledby={props.t.exhibitionGuide}
      aria-describedby={props.t.kioskExhibitionDescription}
    >
    <Slide direction="up" in={props.isModalShown} timeout={350}>
      <Box className={styles.tablet}>
        <Box className={styles.screen}>
        {props.children}

                   
        </Box>
      </Box>
      </Slide>
    </Modal>
  );
}
