import React, { useCallback, useEffect, useState } from 'react';
import { useWindowWidth } from "window-dimensions-hooks";
import SVGViewer from '../organisms/SVGViewer';
import MobileLobby from '../templates/MobileLobby';
import UsePageViews from "../utils/UsePageViews";
import { mobileView } from "../utils/CompatibilityHelper";
import { useParams,useLocation } from "react-router-dom";
import { fetchEvent } from "../utils/FetchData";
import BypassBlock from '../organisms/BypassBlock';
import TopBar from "../organisms/TopBar";
import ModalTablet from "../organisms/ModalTablet";
import KioskExhibition from '../organisms/KioskExhibition.js';
import WelcomeModal from '../organisms/WelcomeModal/WelcomeModal';
import NotFound from './404';
import Config from "../../config";
import { checkdate, updateDate } from '../utils/ModalHelper';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Lobby(props) {
    let { id } = useParams();
    let query = useQuery();
    const [event, setEvent] = useState();
    const [isKioskExhibitionShown, setIsKioskExhibitionShown] = useState(false);
    const [welcomeModalOpen, setWelcomeModalOpen] = useState(checkdate(Config.MODAL_MOTION_SICKNESS_EXPIRE_DELAY));

    const closeWelcomeModal = () => {
        updateDate();
        setWelcomeModalOpen(false);
    };

    const toggleModalTablet = useCallback(isShown => {
        setIsKioskExhibitionShown(isShown);
    }, []);

    useEffect(() => {
        fetchEvent({locale: props.t.getLocale, event_id: id}).then(result => { setEvent(result) })
    }, [id, props.t.getLocale]);

    let width = useWindowWidth();

    // Return not found when event is null.
    if (event === null) return <NotFound />
    // Return null when event not yet set
    if (!event) return null;

    function pageTitle() {
        return `${props.t["Lobby"]} - ${event.event_title} (${event.event_id})`
    }

    function showLobby() {
        let isMobile = (mobileView(width));
        let title= `${isMobile ? props.t.accessible : props.t.webGLTitle} - ${pageTitle()}`;
        let subpage = query.get("subpage");
        let isKioskList = subpage === "kiosklist";

        if (width === 0) return null;

        return (
            <React.Fragment>
                <UsePageViews locale={props.t.getLocale} title={title} />
                <BypassBlock t={props.t} />
                <TopBar
                    t={props.t}
                    title={event.event_title}
                    event_id={event.event_id}
                    mobile={isMobile}
                    main={!isKioskList}
                    kioskList={isKioskList}
                    setWelcomeModalOpen={setWelcomeModalOpen}
                />
                <WelcomeModal
                    t={props.t}
                    open={welcomeModalOpen}
                    close={closeWelcomeModal}
                />
                <ModalTablet
                    t={props.t}
                    event_id={event && event.event_id}
                    kiosks={event.kiosks}
                    isModalShown={isKioskExhibitionShown}
                    toggleModalTablet={toggleModalTablet}
                    isMobile={isMobile}
                >
                    <KioskExhibition
                        t={props.t}
                        kiosks={event.kiosks}
                        isMobile={props.isMobile}
                        event_id={event.event_id}
                    />
                </ModalTablet>
                { isMobile ?
                    <MobileLobby t={props.t} data={event} event_id={event.event_id} lobby={subpage} />
                :
                    <SVGViewer SVG={event.svgURL} toggleModalTablet={toggleModalTablet} event={event} />
                }

            </React.Fragment>
        )
    }

    return showLobby();
}
