import React, {useEffect, useState, useCallback} from 'react';
import {useWindowWidth} from "window-dimensions-hooks";
import MobileSpace from "../templates/MobileSpace";
import BypassBlock from '../organisms/BypassBlock';
import { mobileView } from "../utils/CompatibilityHelper";
import {useHistory, useParams} from "react-router-dom";
import TopBar from "../organisms/TopBar";
import WebGLSpace from "../templates/WebGLSpace";
import MobileMap from "../templates/MobileMap";
import mobileStyles from '../templates/Mobile.module.css';
import KioskExhibition from '../organisms/KioskExhibition';
import styles from "./ESDCVirtualSpace.module.css";
import axios from "axios";
import { fetchESDCSite } from "../utils/FetchData";
import { useLocation } from 'react-router-dom';
import ModalTablet from '../organisms/ModalTablet'
import UsePageViews from '../utils/UsePageViews';
import { Helmet } from "react-helmet-async";
import NotFound from './404';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function ESDCVirtualSpace(props) {
    const [site, setSite] = useState();
    const [space, setSpace] = useState();
    const [isKioskExhibitionShown, setIsKioskExhibitionShown] = useState(false);

    let history = useHistory();
    let { id } = useParams();
    const query = useQuery();
    const showKioskList = (query.get("subpage") === "kiosklist")

    let width = useWindowWidth();

    let spaceID = id;
    let main = (id === 'main')

    if (site && main){
        spaceID = (mobileView(width) ? site.defaultVSpaceIDMobile : site.defaultVSpaceIDWebGL)
    }

    const toggleModalTablet = useCallback(isShown => {
        setIsKioskExhibitionShown(isShown);
    }, []);

    useEffect(() => {
        fetchESDCSite({locale: props.t.getLocale}).then(result => { setSite(result) })
    }, [props.t.getLocale]);

    useEffect(() => {
        if (!site || !spaceID) {return}

        if (!spaceID.startsWith('map')){
            setSpace(site.vSpaces.find(item=>(item.vSpaceID === spaceID)) || null)
            return
        }

        axios.get(`/2113/maps/${spaceID}_${props.t.getLocale}.json`, { baseURL: '' })
            .then(response => {
                setSpace(response.data);
            }).catch(function (error) {
                console.log(error.message);
        });
    }, [site, props.t.getLocale, spaceID]);

    if (space === null) { return <NotFound event_id='2113'/> }

    if (width === 0 || !site || !space ) return null

    // For mobile, render kiosklist, map or a space.
    function vSpaceMobileRender() {
        if (showKioskList) return mobileKiosks();

        return (
            space.type === 'map' ?
                <MobileMap t={props.t} mapSpace={space}/> :
                <MobileSpace t={props.t} space={space}/>
        )
    }

    function pageTitle(){
        return `${props.t.virtualSpace} - ${space.title} (${space.vSpaceID})`
    }

    function mobileKiosks(){
        return (
            <div className={mobileStyles.mobile}>
                <main className={mobileStyles.mainContainer} id="content">
                    <nav className={mobileStyles.navList}>
                    <KioskExhibition
                        t={props.t}
                        kiosks={site.vKiosks}
                        isMobile={true}
                        event_id={site.vWebsiteID}
                    />
                    </nav>
                </main>
            </div>
        )
    }
    const pageIdentifier = (space.pageSlug || "main")

    // Dynamic metadata.
    function metaHelmet(){
        const metaTitle = `${site.title} - ${space.title}`

        return(
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="title" content={metaTitle} />
            </Helmet>
        )
    }

    if (mobileView(width)){
        // If virtualSpace not supported for mobile, redirect to the page specified, or the main space.
        if (!main && !space.availableMobile) {
            history.replace(`/explore/${props.t.esdc}/${props.t.virtualSpacesPath}/${pageIdentifier}`)
        }

        if (!space.availableMobile){ return null }

        let mainMobile = ((site.defaultVSpaceIDMobile === space.vSpaceID) && !showKioskList)

        return (
            <React.Fragment>
                {metaHelmet()}
                <BypassBlock t={props.t}/>
                <UsePageViews locale={props.t.getLocale} title={`${props.t.accessible} - ${pageTitle()}`}/>
                <header>
                    <TopBar t={props.t}
                        title={site.title}
                        space={space}
                        event_id={'2113'}
                        mobile={true}
                        main={mainMobile}
                        vSite={mainMobile && site}
                        kioskList={showKioskList}/>
                </header>
                <main id="content">{vSpaceMobileRender()}</main>
            </React.Fragment>
        )
    }

    // If virtualSpace not supported for WebGL, redirect to the main space
    if (!main && !space.availableWebGL) {
        history.replace(`/explore/${props.t.esdc}/${props.t.virtualSpacesPath}/${pageIdentifier}`)
    }

    if (!space.availableWebGL){ return null }

    return (
        <React.Fragment>
            {metaHelmet()}
            <UsePageViews locale={props.t.getLocale} title={`${props.t.webGLTitle} - ${pageTitle()}`}/>
            <BypassBlock t={props.t}/>
            <header>
                <TopBar t={props.t} title={site.title} space={space} event_id={'2113'}/>
            </header>

            <main id="content" className={styles.spaceContainer}>
                <ModalTablet
                        t={props.t}
                        event_id={site.vWebsiteID}
                        kiosks={site.vKiosks}
                        isModalShown={isKioskExhibitionShown}
                        toggleModalTablet={toggleModalTablet}
                        isMobile={false}
                    >
                        <KioskExhibition
                            t={props.t}
                            kiosks={site.vKiosks}
                            isMobile={false}
                            event_id={site.vWebsiteID}
                        />
                </ModalTablet>
                <WebGLSpace
                    t={props.t}
                    space={space}
                    vSite={site}
                    toggleModalTablet={toggleModalTablet}
                    isKioskExhibitionShown={isKioskExhibitionShown}
                    />
            </main>
        </React.Fragment>
    )
}
