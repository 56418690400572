import React, {useEffect, useState, useCallback} from 'react';
import { useWindowWidth } from "window-dimensions-hooks";
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import BypassBlock from '../organisms/BypassBlock';
import MobileKiosk from '../templates/MobileKiosk';
import VKioskPlayer from '../organisms/vKioskPlayer';
import ModalTablet from '../organisms/ModalTablet';
import KioskList from '../organisms/KioskExhibition';
import UsePageViews from "../utils/UsePageViews";
import { mobileView } from "../utils/CompatibilityHelper";
import { fetchEvent, fetchKiosk } from "../utils/FetchData";
import { slugifyKioskTitle } from '../utils/KioskTitleSlugify';
import styles from './Kiosk.module.css';
import TopBar from "../organisms/TopBar";
import { Helmet } from "react-helmet-async";
import NotFound from './404';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Kiosk(props) {
    const [event, setEvent] = useState();
    const [kiosk, setKiosk] = useState();
    const [isKioskExhibitionShown, setIsKioskExhibitionShown] = useState(false);

    let query = useQuery();

    let history = useHistory();
    let { event_id, id } = useParams();

    // When the event_id is 2100, consider it as a standalone kiosk.
    let standaloneKiosk = (event_id === "2100");

    let draftKiosk = (query.get("draft") === 'true')

    // Fullscreen when query param has fullscreen=true, and it is a standalone kiosk.
    let fullscreen = (query.get("fullscreen") === 'true' && standaloneKiosk)

    const toggleModalTablet = useCallback(isShown => {
        setIsKioskExhibitionShown(isShown);
    }, []);

    // Dynamic metadata.
    function metaHelmet(){
        return(
            <Helmet>
                <title>{pageTitle()}</title>
                <meta name="title" content={pageTitle()} />
            </Helmet>
        )
    }

    useEffect(() => {
        if (standaloneKiosk) { return }

        fetchEvent({locale: props.t.getLocale, event_id: event_id}).then(result => { setEvent(result) })
    }, [event_id, props.t.getLocale, standaloneKiosk]);

    useEffect(() => {
        if(!standaloneKiosk && !event) { return }

        fetchKiosk({locale: props.t.getLocale, kiosk_id: id.split('-')[0], event_id: event_id, draft: draftKiosk })
            .then(result => {
                setKiosk(result);
            })
    }, [event_id, id, props.t.getLocale, standaloneKiosk, event]);

    // Change background color to black for kiosk selection blur
    useEffect(() => {
        if (kiosk && !mobileView(width, standaloneKiosk)) {
            document.body.classList.add('background-black');
        }

        return () => {
            if (!mobileView(width, standaloneKiosk)) {
                document.body.classList.remove('background-black');
            }
        };
    }, [kiosk]);

    let width = useWindowWidth();

    // When event is not found, and it is not a standalone kiosk, return notFound error.
    if (event === null  && !standaloneKiosk) { return <NotFound /> }

    // When no kiosk id, redirect to event first kiosk.
    if (!id) {
        // When event not found, return null for now.
        if (!event) return null

        history.replace(`/${event_id}/${props.t.kiosk}/${slugifyKioskTitle(event.kiosks[0])}`);
        window.location.reload();
    // When kiosk is set to null, return a not found error
    } else if(kiosk === null){
        return <NotFound event_id={event?.event_id}/>
    // When id and no kiosk yet, return empty result (fetching kiosk)
    } else if(!kiosk){
        return null;
    }

    // When no event, or when not a standalone kiosk, return empty result
    if (!event && !standaloneKiosk) return null;

    // When id from the url is vKioskID (not slug with title) redirect to the kiosk url slug
    if (kiosk && id === kiosk.vKioskID){
        return <Redirect push to={`/${event_id}/${props.t.kiosk}/${slugifyKioskTitle(kiosk)}${draftKiosk ? '?draft=true' : ''}` } />
    }

    function pageTitle() {
        let title = `${props.t["Kiosks"]}`;

        return kiosk ? `${title} - ${kiosk.title} (${kiosk.vKioskID})` : title;
    }

    function accessibleKiosk() {
        return (
            <React.Fragment>
                {metaHelmet()}
                <UsePageViews locale={props.t.getLocale} title={`${props.t.accessible} - ${pageTitle()}`}/>
                <BypassBlock t={props.t}/>
                <TopBar t={props.t}
                        title={event?.event_title}
                        event_id={event?.event_id}
                        mobile={true}
                        main={false}
                        kiosk={kiosk}
                        noWidthLimit={standaloneKiosk}
                    />
                <MobileKiosk
                    t={props.t}
                    event_id={event?.event_id}
                    kiosk={kiosk}
                />
            </React.Fragment>
        );
    }

    function defaultKiosk() {
        return (
            <React.Fragment>
                {metaHelmet()}
                <BypassBlock t={props.t}/>
                {!fullscreen &&
                    <header>
                        <TopBar t={props.t}
                                title={event?.event_title}
                                event_id={event?.event_id}
                                kiosk={kiosk}
                                noWidthLimit={standaloneKiosk}
                        />
                    </header>
                }

                <UsePageViews locale={props.t.getLocale} title={`${props.t.webGLTitle} - ${pageTitle()}`}/>
                {event &&
                    <ModalTablet
                        t={props.t}
                        event_id={event.event_id}
                        kiosks={event.kiosks}
                        kiosk={kiosk}
                        isModalShown={isKioskExhibitionShown}
                        toggleModalTablet={toggleModalTablet}
                    >
                        <KioskList
                            t={props.t}
                            kiosks={event.kiosks}
                            kiosk={kiosk}
                            isMobile={props.isMobile}
                            event_id={event.event_id}
                        />
                    </ModalTablet>

                }

                <div className={styles.container}>
                    <main className={styles.kioskContainer} id="content">
                        <VKioskPlayer
                            t={props.t}
                            kiosk={kiosk}
                            toggleModalTablet={toggleModalTablet}
                            isKioskExhibitionShown={isKioskExhibitionShown}
                            backToLobby={backToLobby}
                            event_id={event?.event_id}
                            fullscreen={fullscreen}
                            draftKiosk={draftKiosk}
                        />
                    </main>
                </div>
            </React.Fragment>
        );
    }

    function backToLobby() {
        // Lobby is hardcoded for ESDC for now.
        if (event_id === "2113") {
            history.push(`/explore/${props.t.esdc}/${props.t.virtualSpacesPath}/S211304`)
            return
        }

        const lobbyUrl = props.t.getLocale === "en" ? `/${event_id}/lobby` : `/${event_id}/accueil`;
        history.push(lobbyUrl);
    }

    function showKiosk() {
        if (width === 0) return null

        return (mobileView(width, standaloneKiosk) ? accessibleKiosk() : defaultKiosk());
    }

    return showKiosk();
}
