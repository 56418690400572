import React from 'react';
import { Typography, Button, Grid, Box } from '@mui/material';
import img404 from '../../assets/images/404image.png';
import styles from './404.module.css';

export default function Custom404(props) {
  function homeLink(lang){
    return (props?.event_id ? `/${lang}/#/${props.event_id}` : `/${lang}`)
  }

  return (
    <React.Fragment>
      <Box className={styles.container} sx={{ mx: 6 }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' },
            gap: 5,

          }}
        >
          <Box
            lang="en"
            component="section"
            className={styles.itemSection}
            sx={{ py: { md: 6 } }}
          >
            <Typography component="h2" variant="h4" sx={{ mb: 2 }}>
              Error 404: <br />
              Page not found
            </Typography>
            <Typography variant="p" sx={{ mb: 2 }}>
              Apparently something went wrong. We could not find the content you
              are looking for.
            </Typography>
              <Button
                href={homeLink('en')}
                size="large"
                variant="contained"
                sx={{ bgcolor: '#5F4081' }}
              >
                Return to Home Page
              </Button>
          </Box>
          <Box
            lang="fr"
            component="section"
            className={styles.itemSection}
            sx={{ py: { md: 6 }}}
          >
            <Typography component="h2" variant="h4" sx={{ mb: 2 }}>
              Erreur 404: <br />
              Page non trouvée
            </Typography>
            <Typography variant="p" sx={{ mb: 2 }}>
              Apparemment, quelque chose s&apos;est mal passé. Nous n&apos;avons pas pu
              trouver le contenu que vous recherchez.
            </Typography>

              <Button
                href={homeLink('fr')}
                size="large"
                variant="contained"
                sx={{ bgcolor: '#5F4081' }}
              >
                Retour à la page d&apos;accueil
              </Button>

          </Box>
          <Box>
            <img className={styles.img404} src={img404} alt="" />
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
