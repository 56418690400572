import React, { useEffect } from 'react';
import styles from './MobileMap.module.css';
import {useWindowWidth} from "window-dimensions-hooks";
import { useLocation } from 'react-router-dom';

export default function MobileMap(props) {
    let width = useWindowWidth();
    const location = useLocation();

    useEffect(() => {
        document.body.classList.add('background-grey');

        return () => {
            document.body.classList.remove('background-grey');
        };
    });

    if (width === 0 ) return null

    const iconsStyles = [styles.iconOne, styles.iconTwo, styles.iconThree, styles.iconFour, styles.iconFive];

    function firstFloorMapIcon(content, index) {
        const iconStyle = iconsStyles[index];

        const link = virtualSpaceLink(content);

        return (
            <a href={link} className={`${styles.firstFloor} ${iconStyle} ${styles.flooricon}`} key={link}>
                <div className={styles.mapTexts} style={{borderColor: content.color}}>
                    { content.titleMobile }
                </div>
            </a>
        )
    }

    function secondFloorMapIcon(content, index) {
        const iconStyle = iconsStyles[index];

        const link = virtualSpaceLink(content);

        return (
            <a href={link} className={`${styles.secondFloor} ${iconStyle} ${styles.flooricon}`} key={link}>
                <div className={styles.mapTexts} style={{borderColor: content.color}}>
                    {content.titleMobile}
                </div>
            </a>
        )
    }

    function firstFloorIcons(){
        return(props.mapSpace.contentItems.map((content, index) => (
            firstFloorMapIcon(content, index)
        )))
    }

    function secondFloorIcons(){
        return(props.mapSpace.contentItems.map((content, index) => (
            secondFloorMapIcon(content, index)
        )))
    }

    function renderIcons(){
        return (props.mapSpace.vSpaceID === 'map_2nd_floor' ?
            secondFloorIcons() : firstFloorIcons()
        )
    }

    function virtualSpaceLink(content){
        if (content.type === 'kiosks') {
            return `${window.location.pathname}${window.location.hash.replace(location.search, "")}?subpage=kiosklist`
        }

        return `#/explore/${props.t.esdc}/${props.t.virtualSpacesPath}/${content.spaceId}`
    }

    return (
        <div className={styles.mainSpace}>
            <div className={styles.container}>
                <img src={`/2113/mobile/${props.mapSpace.bannerImageURLMobile}`} alt={""} className={styles.mainImage}/>
                { renderIcons() }
            </div>
        </div>
    );
}
