import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Card, CardActionArea, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';

const StyledStartButton = styled(Button)(({ theme }) => ({
    fontSize: 16,
    borderRadius: theme.shape.borderRadius,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    textTransform: 'none',
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #797979',
    boxShadow: `
        0px 0px 0px -2px #c7c7c7,
        0px 0px 0px 0px #c7c7c7,
        0px 2px 0px 0px #c7c7c7
    `,
    textShadow: 'none',
}));

export default function Mode(props) {
    const IconComponent = props.iconComponent;
    return (
        <Card sx={{
            display: 'flex',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            border: theme => `1px solid ${theme.palette.common.white}`,
            transition: theme => theme.transitions.create(['box-shadow'], {
                duration: theme.transitions.duration.short,
            }),
            '&:hover': {
                outline: theme => `1px solid ${theme.palette.common.white}`,
                boxShadow: `
                    0px 0px 3px -2px rgba(255, 255, 255, 0.75),
                    0px 0px 4px 0px rgba(255, 255, 255, 0.75),
                    0px 0px 8px 0px rgba(255, 255, 255, 0.75)
                `,
                '& .start': {
                    color: theme => theme.palette.common.white,
                    backgroundColor: '#704a78',
                    borderColor: '#c567ff',
                    boxShadow: `
                        0px 0px 0px -2px #5f2d7c,
                        0px 0px 0px 0px #5f2d7c,
                        0px 2px 0px 0px #5f2d7c
                    `
                },
            },
        }}>
            <CardActionArea
                sx={{
                    backgroundColor: 'transparent',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    textShadow: theme => `1px 1px 2px ${theme.palette.common.black}`,
                }}
                onClick={() => props.onClick()}
            >
                <Box sx={{
                    width: '100%',
                    boxSizing: 'border-box',
                    p: 2,
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconComponent sx={{ mr: 1 }} />
                        <Typography variant="body2" component="h3">
                            {props.name}
                        </Typography>
                    </Box>
                    <Typography variant="caption" component="span">
                        {props.description}
                    </Typography>
                </Box>
                <Box sx={{
                    width: '100%',
                    boxSizing: 'border-box',
                    p: 2,
                }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <StyledStartButton component="div" className="start" tabIndex={-1} disableRipple>
                            {props.callToAction}
                        </StyledStartButton>
                    </Box>
                    <Box component="ul" sx={{ m: 0, pl: 2 }}>
                        {props.notes.map((note, index) => (
                            <Typography key={index} variant="caption" component="li">
                                {note}
                            </Typography>
                        ))}
                    </Box>
                </Box>
            </CardActionArea>
        </Card>
    );
}

Mode.propTypes = {
    callToAction: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    iconComponent: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    notes: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
};
