import React, { useState, useEffect } from 'react';
import styles from './SVGViewer.module.css';

export default function SVGViewer(props) {
    const [SVG, setSVG] = useState(null);
    const { toggleModalTablet } = props;

    useEffect(() => {
        async function getSVGText() {
            let file = await fetch(props.SVG);
            file = await file.text();
            setSVG(file);
        }

        getSVGText();
    }, [props.SVG]);

    // Add kiosk exhibition toggle to kiosk link in SVG
    useEffect(() => {
        if (!SVG) { return }

        function toggleKioskSelection(){
            toggleModalTablet(true);
        }

        let kioskLink = document.getElementById('kiosks_selection_toggle');

        if (kioskLink) {
            // Force pointer style for kiosk selection
            kioskLink.style.cursor = 'pointer'
            kioskLink.addEventListener('click', toggleKioskSelection);
            kioskLink.addEventListener('keypress', function (e) {
                if (e.key === 'Enter') {
                    toggleKioskSelection();
                }
            });
        }
        //keyboard browsing and labels
        let linkList = document.getElementById('layer1')?.children;
        if (!linkList) return

        // For CSPS events, update links in SVG for the one coming from the event object.
        function dynamicLinkCSPS(link){
            if (!props.event.courseCode) return

            if(link.id === "main-presentation"){
                const content = props.event.lobby.find(item => (item.contentItemID === "mainPresentation"))

                if (content?.linkURL){
                    link.setAttribute("href", content.linkURL);
                }
            }
        }

        for (let i = 0; i < linkList.length; i++) {
            if(linkList[i].tagName === "a"){
                dynamicLinkCSPS(linkList[i])
                //this seems to be fixing the kiosk focusability. can'T say why
                linkList[i].setAttribute("onfocus", "return false;");
                //set label
                linkList[i].setAttribute("aria-labelledby", linkList[i].querySelector("text").getAttribute("id"));
            }
        }

    }, [SVG, toggleModalTablet]);

    return (
        <div className={styles.SVGContainer} dangerouslySetInnerHTML={{ __html: SVG }} id="content" />
    );
}
