import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppEn from './AppEn';
import AppFr from './AppFr';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import { HelmetProvider } from 'react-helmet-async';

axios.defaults.baseURL = `${process.env.REACT_APP_API_HOST}/api/`;
axios.defaults.headers = { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0' };
axios.defaults.timeout = 10000

// If in production use the lang from documentElement to render the french version.
// Otherwise, use the pathname.
if (document.documentElement.lang === "fr" || (process.env.NODE_ENV !== 'production' && window.location.pathname === '/fr/')){
    ReactDOM.render(
        <React.StrictMode>
            <HelmetProvider>
                <AppFr/>
            </HelmetProvider>
        </React.StrictMode>,
        document.getElementById('root')
    );
} else {
    ReactDOM.render(
        <React.StrictMode>
            <HelmetProvider>
                <AppEn/>
            </HelmetProvider>
        </React.StrictMode>,
        document.getElementById('root')
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
