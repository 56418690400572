const i18nFr = {
    restart: 'Redémarrer',
    rewind: 'Recommencer {seektime}s',
    play: 'Lire',
    pause: 'Pause',
    fastForward: 'Avancer {seektime}s',
    seek: 'Chercher',
    seekLabel: '{currentTime} de {duration}',
    played: 'Joué',
    buffered: 'Tamponné',
    currentTime: 'Position actuelle',
    duration: 'Durée',
    volume: 'Volume',
    mute: 'Activer le mode muet',
    unmute: 'Désactiver le mode muet',
    enableCaptions: 'Activer les sous-titres',
    disableCaptions: 'Désactiver les sous-titres',
    download: 'Télécharger',
    enterFullscreen: 'Passer en mode plein écran',
    exitFullscreen: 'Quitter le mode plein écran',
    frameTitle: 'Lecteur pour {title}',
    captions: 'Sous-titres',
    settings: 'Paramètres',
    pip: 'PIP',
    menuBack: 'Revenir au menu précédent',
    speed: 'Vitesse',
    normal: 'Normal',
    quality: 'Qualité',
    loop: 'Loop',
    start: 'Début',
    end: 'Fin',
    all: 'Tout',
    reset: 'Réinitialiser',
    disabled: 'Désactivé',
    enabled: 'Activé',
    advertisement: 'Annonce',
    qualityBadge: {
        2160: '4K',
        1440: 'HD',
        1080: 'HD',
        720: 'HD',
        576: 'SD',
        480: 'SD',
    }
}

export default i18nFr;
