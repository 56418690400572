import React from 'react';
import NavLink from '../organisms/NavLink';
import styles from './Mobile.module.css';
import IconText from '../organisms/IconText';
import KioskExhibition from '../organisms/KioskExhibition';
import { incompatibleBrowserFor3DVersion } from "../utils/CompatibilityHelper";

export default function MobileLobby(props) {
    function displayBanner(){
        if(props.lobby !== "kiosklist"){
            return (<div style={{backgroundImage: `url(${jpgThumbnail})`}} className={styles.bgImage}></div>)
        }
    }

    // If the browser is an unsupported browser it display the incompatible browser message to tell the user how to view the 3d view
    function displayWarning(){
        if (incompatibleBrowserFor3DVersion()) {
            return (
                <a href={props.t.incompatibleBrowserUrl} key={props.t.incompatibleBrowser + "_card"} target="_blank" rel="noreferrer" className={styles.warnBrowser}>
                    <span className="invisible">{ props.t.warnNewWindow }</span>
                    <IconText t={props.t} icon='warning' key="warning">
                        <p>{props.t.incompatibleBrowserMessage}</p>
                    </IconText>
                </a>
            );
        }
    }

    function showKiosks(){
        return (
            <KioskExhibition
                t={props.t}
                kiosks={props.data.kiosks}
                isMobile={true}
                event_id={props.event_id}
            />
        )
    }

    function renderLobby() {
        // Array to hold the output that will be rendered
        let output = [];

        const kioskListLink = `/${props.t.getLocale}/#/${props.data.event_id}/${props.t.Lobby.toLowerCase()}?subpage=kiosklist`
        // Loop through data, assign current object to section
        props.data.lobby.forEach(item => {
            const linkURL = (item.contentItemID === 'kioskList' ? kioskListLink : item.linkURL)
            let lobbyFlag = (linkURL.indexOf(props.data.event_id + "/" + props.t.Lobby.toLowerCase()) >= 0 );

            // Link the content, show in a card view
            output.push(
                <NavLink data={item} warning={props.t.warnNewWindow} key={ item.title || item.description } url={linkURL} self={ lobbyFlag ? true : false}/>
            );
        });
        return output;
    }

    const locale = props.t.getLocale.charAt(0).toUpperCase() + props.t.getLocale.slice(1);
    // Support CSPS event_id (when event_id is separated by - with courseCode))
    const id = props.data.event_id.split('-')[0]

    const jpgThumbnail = `/${id}/Images/vLobby_${locale}CA_${id}.jpg`;

    return (
        <div className={styles.mobile}>
            <main className={styles.mainContainer} id="content">
                <nav className={styles.navList}>
                    { displayBanner() }
                    { displayWarning() }
                    { ( props.lobby === "kiosklist") ? showKiosks() : renderLobby() }
                </nav>
            </main>
        </div>
    );
}
