
const today = new Date();
const stringToday=today.toISOString();

/**
 * checks date against delay
 * @param {*} delay 
 * @returns boolean
 */
export function checkdate(delay){
    let showModal;
    if(testStorage() || typeof delay === "undefined"){
        //local storage works, so lets see if we DO have this variable
        const getDate = localStorage.getItem("modalDate");
        if ( getDate === null) {
            showModal = true;
        }else{
            //converts stored date
            let checkDate = Date.parse(getDate);
            let dayDiff = Math.round((today - checkDate) / (1000 * 60 * 60 * 24));
            //check the number of days against delay
            showModal = (dayDiff < delay)? false : true ;
        }
    }else{
        //localstorage is not available too bad you have to see the modal everytime now.
        showModal = true;
    }
    return showModal;
}

/**
 * update the stored date
 */
export function updateDate(){
    localStorage.setItem("modalDate", stringToday);
}

/**
 * test local Storage capacity
 * @returns boolean 
 */
function testStorage(){
    var test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch(e) {
        return false;
    }
}
