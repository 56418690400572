import React, { useState } from 'react';
import styles from './TopBarMobile.module.css';
import IconButton from '@mui/material/IconButton';
import RoomIcon from '@mui/icons-material/RoomOutlined';
import back_button_src from '../../assets/icons/mobile_back_btn.png';
import map_info_btn from '../../assets/icons/mobile_map_info_btn.png';
import IntroMessage from './IntroMessage';
import IntroMessageMobile from './IntroMessageMobile';
import { useWindowWidth } from 'window-dimensions-hooks';
import mobile_hint_btn from '../../assets/icons/mobile_hint_btn.png';
import HelpHintModal from './HelpHintModal';

const buttonStyle = {
    mx: (theme) => theme.spacing(2),
    borderRadius: 0,
};

export default function TopBarMobile(props) {
    let width = useWindowWidth();

    const [showHelpHintMessage, setHelpHintMessage] = useState(false);

    const [showMessage, setShowMessage] = useState(
        props.vSite && localStorage.viewIntroMessage === undefined
    );

    function title() {
        if (props.kioskList) {
            return props.t.KioskExhibition;
        } else if (props.space) {
            return props.space.title;
        } else if (props.kiosk) {
            return props.t.kiosk;
        } else {
            return props.t.Lobby;
        }
    }

    function openModal() {
        setShowMessage(true);
    }

    function openWelcomeModal() {
        props.setWelcomeModalOpen(true);
    }

    function closeModal() {
        setShowMessage(false);
    }

    function openHelpHintModal() {
        setHelpHintMessage(true);
    }

    function closeHelpHintModal() {
        setHelpHintMessage(false);
    }

    function introModal() {
        if (width > 1000) {
            return (
                <IntroMessage
                    t={props.t}
                    show={showMessage}
                    parentCallback={closeModal}
                    vSite={props.vSite}
                />
            );
        }

        return (
            <IntroMessageMobile
                t={props.t}
                show={showMessage}
                parentCallback={closeModal}
                vSite={props.vSite}
            />
        );
    }

    function infoIconButton() {
        let onClick;
        if (props.space && props.vSite) {
            onClick = openModal;
        } else {
            onClick = openWelcomeModal;
        }
        return (
            <IconButton
                onClick={onClick}
                aria-label={props.t.openIntro}
                sx={buttonStyle}
            >
                <img src={map_info_btn} className={styles.iconImage} alt="" />
            </IconButton>
        );
    }

    function hintIconButton() {
        if (props.space?.type !== 'map' || props.kioskList) return;

        return (
            <IconButton
                onClick={openHelpHintModal}
                aria-label={props.t.openHint}
                sx={buttonStyle}
            >
                <img
                    src={mobile_hint_btn}
                    className={styles.iconImage}
                    alt=""
                />
            </IconButton>
        );
    }

    function backIconButton() {
        //standalone
        if (typeof props.event_id === 'undefined' || props.event_id === 2100) {
            return null;
        }

        let backLink = '';
        if (props.space?.previousSpaceIDMobile) {
            //workplace spaces
            backLink = `#/explore/${props.t.esdc}/${props.t.virtualSpacesPath}/${props.space.previousSpaceIDMobile}`;
        } else if (props.kiosk) {
            //ESDC kiosk
            if (props.event_id === '2113') {
                backLink = `#/explore/${props.t.esdc}/${props.t.virtualSpacesPath}/main?subpage=kiosklist`;
            } else {
                //vExpo kiosk returns to kioskList
                backLink = `#/${props.event_id}/${props.t.Lobby}?subpage=kiosklist`;
            }
        } else if (props.event_id === '2113') {
            // Main ESDC page
            backLink = `#/explore/${props.t.esdc}/${props.t.virtualSpacesPath}/main`;
        } else {
            //Lobby!
            backLink = `#/${props.event_id}/${props.t.Lobby.toLowerCase()}`;
        }
        return (
            <IconButton
                aria-label={props.t.backToLobby}
                href={backLink}
                className={styles.backbtn}
                sx={buttonStyle}
            >
                <img
                    src={back_button_src}
                    className={styles.iconImage}
                    alt=""
                />
            </IconButton>
        );
    }

    function leftButton() {
        return props.main ? infoIconButton() : backIconButton();
    }

    function helpModal() {
        if (props.space?.type !== 'map') return;

        return (
            <HelpHintModal
                t={props.t}
                show={showHelpHintMessage}
                parentCallback={closeHelpHintModal}
                vspace={props.space}
            />
        );
    }

    return (
        <div className={styles.topBarMobile}>
            <div className={styles.mobileLeft}>{leftButton()}</div>

            <h2 className={styles.mobileTitle}>
                <RoomIcon fontSize={'medium'}></RoomIcon>
                {title().toUpperCase()}
            </h2>

            <div className={styles.mobileRight}>{hintIconButton()}</div>

            {showMessage && introModal()}

            {showHelpHintMessage && helpModal()}
        </div>
    );
}
