import React from 'react';
import IconText from './IconText';
import styles from './NavLink.module.css';


export default function NavLink(props) {

    if (!(props.data?.description || props.data?.title)) return null
    return (
        <a href={ props.url }     
                key={ props.title || props.description || props.linkURL }
                target={props.self?"_self":"_blank"}
                rel="noreferrer"
                ref={props.reference}
                className={styles.link}
            >
                <span className="invisible">{ props.warning }</span>
                <IconText t={props.t} url={props.data.iconImageURL || props.data.iconImageURLMobile}>
                        {props.data.description || props.data.title}
                </IconText>
        </a>
    );
}
