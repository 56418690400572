import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import './IntroMessage.css'
import customStyles from './IntroMessage.module.css'
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ReactMarkdown from 'react-markdown'

const buttonStyle = {
    backgroundColor: '#345074',
    color: 'white',
    boxShadow: '0 4px 1px #000000',
    width: '300px',
    borderRadius: '8px',
    fontFamily: 'Lato, sans-serif',
    fontSize: '1.2rem',
    textTransform: 'none',
    ':hover': { backgroundColor: '#345074'}
}

export default function IntroMessageMobile(props) {
    const [open, setOpen] = React.useState(props.show);

    function handleClose() {
        props.parentCallback && props.parentCallback();
        localStorage.viewIntroMessage = true;
        setOpen(false);
    }

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} PaperProps={{style: { borderRadius: 5, margin: "12px" }}}>
                <DialogTitle id="dialog-title" onClose={handleClose} t={props.t}>
                    {open ? (
                        <IconButton aria-label={props.t.close} onClick={handleClose} sx={{position: 'absolute', right: '8px', top: '8px', padding: '8px'}}>
                            <CloseIcon fontSize={'medium'} ></CloseIcon>
                        </IconButton>
                    ) : null}
                    <img className={customStyles.responsive} src={`/${props.vSite.vWebsiteID}/icons/${props.vSite.logoURL}`} alt={''}/>
                </DialogTitle>
                <DialogContent id="dialog-content" style={{color: '#4F5B72', textAlign: 'center'}}>
                    <ReactMarkdown >
                        { props.vSite.description }
                    </ReactMarkdown>
                </DialogContent>
                <DialogActions sx={{padding: '20px', justifyContent: 'center', alignItems: 'center'}}>
                    <Button onClick={handleClose} style={buttonStyle}>
                        { props.t.startVisit }
                    </Button>
                </DialogActions>
                <DialogContent sx={{backgroundColor: '#e0e0e0', overflowY: 'hidden'}}>
                    <img className={customStyles.fipResponsive}
                         src={`/${props.vSite.vWebsiteID}/${props.vSite.FIP.serviceTitlesImageURL}`}
                         alt={props.vSite.FIP.serviceTitles} />
                </DialogContent>
            </Dialog>
        </div>
    );
}
