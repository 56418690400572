import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from './ESDCModalMap.module.css'
import axios from 'axios';

const buttonStyle = {
    backgroundColor: '#345074',
    color: 'white',
    boxShadow: '0 4px 1px #000000',
    width: '250px',
    borderRadius: '8px',
    lineHeight: '2',
    fontSize: '20px',
    fontFamily: 'Lato, sans-serif',
    textTransform: 'none',
    ':hover': { backgroundColor: '#345074'}
}

const titleStyle = {
    fontFamily: 'Lato, sans-serif',
    padding: "16px 24px",
    textAlign: 'center',
    fontSize: '2rem',
    ['@media screen and (max-height: 650px)']: {
        fontSize: '1.5rem',
    }
}

const secondFloorSpace = ['S211311', 'S211306', 'S211307', 'S211308'];

export default function ESDCModalMap(props) {
    const [open, setOpen] = React.useState(props.show);
    const [mapData, setMapData] = React.useState();

    function handleClose() {
        props.parentCallback && props.parentCallback();
        setOpen(false);
    }

    function mapPath() {
        const mapFilename = (secondFloorSpace.includes(props.vSpaceID) ? 'map_2nd_floor_webgl' : 'map_1st_floor_webgl')
        return `/2113/maps/${mapFilename}_${props.t.getLocale}.json`
    }

    const iconsStyles = [
        styles.iconOne,
        styles.iconTwo,
        styles.iconThree,
        styles.iconFour,
        styles.iconFive
    ];

    useEffect(() => {
        axios.get(mapPath(), { baseURL: '' })
            .then(response => {
                setMapData(response.data);
            }).catch(function (error) {
                console.log(error.message);
        });
    }, [props]);

    if (!mapData) return null

    function firstFloorMapIcon(content, index) {
        const iconClass = `${styles.firstFloor} ${iconsStyles[index]} ${styles.flooricon}`;

        return (
            <a href={vSpaceLink(content)} onClick={handleClose} className={iconClass} key={vSpaceLink(content)}>
                <div className={styles.mapTexts} style={{borderColor: content.color}}>
                    { content.title }
                </div>
                <div className={`${styles.description} ${styles.hide}`}>
                    <p>{ content.description }</p>
                </div>
            </a>
        )
    }

    function secondFloorMapIcon(content, index) {
        const iconClass = `${styles.secondFloor} ${iconsStyles[index]} ${styles.flooricon}`;

        return (
            <a href={vSpaceLink(content)} onClick={handleClose} className={iconClass} key={vSpaceLink(content)}>
                <div className={styles.mapTexts} style={{borderColor: content.color}}>
                    { content.title }
                </div>
                <div className={`${styles.description} ${styles.hide}`}>
                    <p>{ content.description }</p>
                </div>
            </a>
        )
    }

    function vSpaceLink(content){
        return `#/explore/${props.t.esdc}/${props.t.virtualSpacesPath}/${content.spaceId}`;
    }

    function secondFloorIcons(){
        return(mapData.contentItems.map((content, index) => (
            secondFloorMapIcon(content, index)
        )))
    }

    function firstFloorIcons(){
        return(mapData.contentItems.map((content, index) => (
            firstFloorMapIcon(content, index)
        )))
    }

    function renderIcons(){
        return (secondFloorSpace.includes(props.vSpaceID) ? secondFloorIcons() : firstFloorIcons())
    }

    return (
        <Dialog onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                PaperProps={{style: { borderRadius: "5px 5px 5px 5px", maxWidth: "1000px"}}}>
            <DialogTitle id="customized-dialog-title" t={props.t} sx={titleStyle} >
                <IconButton aria-label={props.t.close} onClick={handleClose} sx={{position: 'absolute', right: '8px', top: '8px'}}>
                    <CloseIcon fontSize={'large'} ></CloseIcon>
                </IconButton>
                {mapData.description}
            </DialogTitle>
            <DialogContent sx={{fontFamily: 'Lato, sans-serif', padding: "0", position: "relative"}}>
                <div className={styles.mapTitle}>
                    <h3>{mapData.title}</h3>
                </div>
                <img src={`/2113/images/${mapData.backgroundImageURLWebGL}`} className={styles.backgroundMap}/>
                { renderIcons() }
            </DialogContent>
            <DialogActions sx={{padding: '20px', justifyContent: 'center'}}>
                <Button onClick={handleClose} sx={buttonStyle}>
                    { props.t.close }
                </Button>
            </DialogActions>
        </Dialog>
    );
}
