import React, { useEffect, useState, useCallback } from 'react';
import { Unity, useUnityContext } from "react-unity-webgl";
import styles from './vKioskPlayer.module.css';
import './WebGLUnity.css';

export default function VKioskPlayer(props) {
    const [isLoaded, setIsLoaded] = useState(false);

    const { toggleModalTablet } = props;

    const { unityProvider, unload, loadingProgression, addEventListener, removeEventListener, sendMessage } = useUnityContext({
        loaderUrl: "/VirtualKiosk/Build/VirtualKiosk.loader.js",
        dataUrl: "/VirtualKiosk/Build/VirtualKiosk.data.br",
        frameworkUrl: "/VirtualKiosk/Build/VirtualKiosk.framework.js.br",
        codeUrl: "/VirtualKiosk/Build/VirtualKiosk.wasm.br",
        streamingAssetsUrl: "StreamingAssets",
        companyName: "CSPS",
        productName: "2100_Virtual Expo",
        productVersion: "0.1"
    });

    const loadingPercentage = Math.round(loadingProgression * 100);

    // GA kiosk loaded event.
    function kioskLoadedEvent(vKioskID) {
        sendMessage('Application', 'EnableGA', 'true');
        console.log('Google analytics is ready');
        window.dataLayer.push({
            event: 'loadedvKiosk',
            customEvent: {
                label: vKioskID
            },
            page: {
                path: window.location.pathname + window.location.hash,
                url: window.location.href
            }
        });
    }

    const loadKiosk = useCallback(() => {
        // vKiosk Player Localisation
        // param is a string[] with '#' splitter
        // [0] = UI Element
        // [1] = localised string
        const lang = (props.t.getLocale === "en" ? "EnCA" : "FrCA")
        sendMessage('Application', 'SetLanguage', lang);
        sendMessage('UI/UITK', 'SetUILabelText', `BackButtonBG#${props.t.backToLobby}`);
        sendMessage('UI/UITK', 'SetUILabelText', `ToolButtonBG#${props.t.exhibitionGuide}`);

        // vKiosk Player UI Controls
        // param is a string[] with '#' splitter
        // [0] = UI Element
        // [1] = isVisible
        sendMessage('UI/UITK', 'SetUIEnabled', `BackButtonBG#${props.event_id ? 'true' : 'false'}`);
        sendMessage('UI/UITK', 'SetUIEnabled', `ToolButtonBG#${props.event_id ? 'true' : 'false'}`);
        sendMessage('UI/UITK', 'SetUIEnabled', 'MapButtonBG#false');
        sendMessage('UI/UITK', 'SetUIEnabled', 'HintsButtonBG#false');

        if (props.kiosk) {
            sendMessage('Application', 'SetDraftMode', `${props.draftKiosk}`);
            sendMessage('Application', 'LoadKiosk', `${props.kiosk.vKioskID}`);
            sendMessage('UI/UITK', 'SetUIVisible', 'ToolButtonBG#true'); // default delay if new kiosk
            kioskLoadedEvent(props.kiosk?.vKioskID);
        }
    }, [props.event_id, props.kiosk, props.draftKiosk, props.t.backToLobby, props.t.exhibitionGuide, props.t.getLocale, sendMessage]);

    // For unity issue https://github.com/jeffreylanters/react-unity-webgl/issues/250
    // block alert window
    useEffect(() => {
        window.alert = console.log
    }, []);

    // Manage what happens when the player is loaded
    // or when a kiosk is selected in KioskExhibition
    useEffect(() => {
        if (!isLoaded || !props.kiosk) {
            return;
        }

        loadKiosk();
        toggleModalTablet(false);
    }, [isLoaded, loadKiosk, toggleModalTablet, props.kiosk]);

    const KioskPlayerStartEvent = useCallback(() => {
        setIsLoaded(true);
        sendMessage('UI/UITK', 'SetUIVisible', 'ToolButtonBG#false'); // Hide it the first time vKiosk Player is loaded
    }, [sendMessage]);

    const KioskPlayerBtnClickEvent = useCallback(async (buttonName) => {
        if (buttonName === "Tool Btn Callback") {
            props.toggleModalTablet(true);
        } else if (buttonName === "Back Btn Callback") {
            await unload();
            props.backToLobby();
        }
    }, [unload]);

    // Manage vKiosk Player when KioskExhibition Open Closes
    useEffect(() => {
        if (!isLoaded || !props.event_id) return;

        // kiosk exhibition has been closed
        if (!props.isKioskExhibitionShown) {
            sendMessage('Application', 'SetResumeState');
            sendMessage('UI/UITK', 'SetUIVisible', 'ToolButtonBG#true#0'); // 0ms delay if no new kiosk
        } else {
            sendMessage('Application', 'SetPauseState');
            sendMessage('UI/UITK', 'SetUIVisible', 'ToolButtonBG#false');
        }
    }, [isLoaded, props.isKioskExhibitionShown, props.event_id]);

    // Unload function if loading progression is completed.
    const cleanup = async () => {
        if (loadingProgression === 1) {
            await unload();
        }
    }

    useEffect(() => {
        addEventListener("KioskPlayerStartEvent", KioskPlayerStartEvent)
        addEventListener("KioskPlayerBtnClickEvent", KioskPlayerBtnClickEvent)

        return () => {
            removeEventListener("KioskPlayerStartEvent", KioskPlayerStartEvent)
            removeEventListener("KioskPlayerBtnClickEvent", KioskPlayerBtnClickEvent)

            // Try to unload on unmount.
            // Needed when changing page without the back button.
            // Without unload, the player will keep raising errors in loop.
            // https://github.com/jeffreylanters/react-unity-webgl/issues/22
            cleanup()
        };
    }, [
        addEventListener,
        removeEventListener,
        KioskPlayerStartEvent,
        KioskPlayerBtnClickEvent
    ]);

    let unityLoadingClasses = [styles.unityLoading, isLoaded ? styles.loaded : ''];

    // When fullscreen props present, use different style to take all space.
    const containerStyle = (props.fullscreen ? styles.webGLContainerFullscreen : styles.webGLContainer)

    return (
        <div className={containerStyle}>
            <div id="unity-container" className={styles.unityContainer}>
                <div id="unity-loading" className={unityLoadingClasses.join(' ')}>
                    <div id="unity-loading-bar" className={styles.unityLoadingBar}>
                        <div className="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <div id="unity-progress-bar-empty">
                            <div id="unity-progress-bar-full" style={{ width: loadingPercentage + "%" }}></div>
                        </div>
                    </div>
                </div>
                <Unity unityProvider={unityProvider} className={styles.unityCanvas} />
            </div>
        </div>
    );
}
