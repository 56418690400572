import React from "react";
import PropTypes from "prop-types";
import { Backdrop, Box, Fade, Grid, Modal, Typography } from "@mui/material";
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Mode from "./Mode";
import styles from './WelcomeModal.module.css';
import { useHistory } from "react-router-dom";
import { setListMode, setExplorationMode, isListMode } from "../../utils/CompatibilityHelper";

export default function WelcomeModal(props) {
    const history = useHistory();

    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        },
    });

    function switchToListMode() {
        const isCurrentlylistMode = isListMode();

        props.close();

        if (!isCurrentlylistMode) {
            setListMode();
            history.go(0);
        }
    }
    function switchToExplorationMode() {
        const isCurrentlylistMode = isListMode();

        props.close();

        if (isCurrentlylistMode) {
            setExplorationMode();
            history.go(0);
        }
    }

    return (
        <Modal
            open={props.open}
            onClose={props.close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={styles.modalcontainer}
            sx={{
                justifyContent: { xs: 'flex-start', lg: 'center' },
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 250,
            }}
        >
            <Fade in={props.open}>
                <Box className={styles.modalbox} sx={
                    theme => ({
                        [theme.breakpoints.down('sm')]: {
                            height: 'auto',
                        },
                        [theme.breakpoints.down('md')]: {
                            width: '100%',
                            boxSizing: 'border-box',
                        },
                    })
                }>
                    <Box
                        className={styles.header}
                        sx={{
                            alignSelf: 'flex-end',
                            minHeight: { xs: '5vh', sm: '12vh', md: '15vh' },
                            '&:before': {
                                width: { xs: '35vw', sm: '27vw', md: '20vw', lg: '21vw' },
                                height: { xs: '12vh', sm: '16vh', md: '18vh', lg: '19vw' },
                            },
                        }}
                    />
                    <Box sx={{ p: 4 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={8}>
                                <Typography
                                    variant="h2"
                                    sx={{
                                        textShadow: theme => `1px 1px 2px ${theme.palette.common.black}`,
                                    }}
                                    gutterBottom
                                >
                                    {props.t.titleWarnMovement}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    paragraph
                                    sx={{
                                        textShadow: theme => `1px 1px 2px ${theme.palette.common.black}`,
                                    }}
                                >
                                    {props.t.contentWarnMovement1}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    paragraph
                                    sx={{
                                        textShadow: theme => `1px 1px 2px ${theme.palette.common.black}`,
                                    }}
                                >
                                    {isListMode() ?
                                        props.t.contentWarnMovement2Mobile
                                        : props.t.contentWarnMovement2
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* hide the modes in list mode as only list mode is available on mobile */}
                        {!isListMode() &&
                            <ThemeProvider theme={darkTheme}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <Mode
                                            iconComponent={ListAltOutlinedIcon}
                                            name={props.t.listMode}
                                            description={props.t.listModeDescription}
                                            callToAction={props.t.listModeCTA}
                                            onClick={switchToListMode}
                                            notes={props.t.listModeNotes}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <Mode
                                            iconComponent={ViewInArOutlinedIcon}
                                            name={props.t.explorationMode}
                                            description={props.t.explorationModeDescription}
                                            callToAction={props.t.explorationModeCTA}
                                            onClick={switchToExplorationMode}
                                            notes={props.t.explorationModeNotes}
                                        />
                                    </Grid>
                                </Grid>
                            </ThemeProvider>
                        }
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}

WelcomeModal.propTypes = {
    t: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
};
